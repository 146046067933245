@charset "UTF-8";
@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Regular-webfont.ttf");
  src: url("../fonts/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular-webfont.woff") format("woff"), url("../fonts/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Bold-webfont.ttf");
  src: url("../fonts/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold-webfont.woff") format("woff"), url("../fonts/OpenSans-Bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Italic-webfont.ttf");
  src: url("../fonts/OpenSans-Italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Italic-webfont.woff") format("woff"), url("../fonts/OpenSans-Italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-BoldItalic-webfont.ttf");
  src: url("../fonts/OpenSans-BoldItalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-BoldItalic-webfont.woff") format("woff"), url("../fonts/OpenSans-BoldItalic-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

/**
RESET
**/
* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  box-sizing: border-box; }

main, section, aside, subline, subhead, header, footer {
  display: block; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

button {
  cursor: pointer;
  background: none;
  border: none; }

input, button, textarea {
  font-family: 'OpenSans';
  font-size: 1em; }

html {
  height: 100%;
  min-height: 100%; }

body {
  font-family: 'OpenSans';
  color: #333333;
  height: 100%;
  min-height: 100%; }

a {
  color: #333333;
  text-decoration: underline; }

img {
  max-width: 100%;
  height: auto; }

.button, .b-form-button--save, .b-form-button--cancel, .b-form-button--remove, .b-form-button--hide, .b-form-button--unhide, .b-danger__button {
  display: block;
  width: 100%;
  padding: 15px;
  background: rgba(76, 97, 234, 0.8);
  border: 1px solid rgba(76, 97, 234, 0.8);
  border-radius: 6px;
  color: #FFFFFF;
  text-decoration: none; }
  .button:not(:disabled):hover, .b-form-button--save:not(:disabled):hover, .b-form-button--cancel:not(:disabled):hover, .b-form-button--remove:not(:disabled):hover, .b-form-button--hide:not(:disabled):hover, .b-form-button--unhide:not(:disabled):hover, .b-danger__button:not(:disabled):hover {
    background: rgba(76, 97, 234, 0.9);
    border-color: #4C61EA; }
  .button:not(:disabled):active, .b-form-button--save:not(:disabled):active, .b-form-button--cancel:not(:disabled):active, .b-form-button--remove:not(:disabled):active, .b-form-button--hide:not(:disabled):active, .b-form-button--unhide:not(:disabled):active, .b-danger__button:not(:disabled):active {
    background: #4C61EA;
    border-color: #312480; }
  .button:disabled, .b-form-button--save:disabled, .b-form-button--cancel:disabled, .b-form-button--remove:disabled, .b-form-button--hide:disabled, .b-form-button--unhide:disabled, .b-danger__button:disabled {
    cursor: not-allowed;
    opacity: 0.6; }
  .button--secondary, .b-form-button--remove, .b-form-button--hide, .b-form-button--unhide {
    background: rgba(42, 47, 54, 0.8);
    border: 1px solid rgba(42, 47, 54, 0.8); }
    .button--secondary:not(:disabled):hover, .b-form-button--remove:not(:disabled):hover, .b-form-button--hide:not(:disabled):hover, .b-form-button--unhide:not(:disabled):hover {
      background: rgba(42, 47, 54, 0.9);
      border-color: #2A2F36; }
    .button--secondary:not(:disabled):active, .b-form-button--remove:not(:disabled):active, .b-form-button--hide:not(:disabled):active, .b-form-button--unhide:not(:disabled):active {
      background: #2A2F36;
      border-color: #2A2F36; }
  .button--text, .b-form-button--cancel {
    color: rgba(42, 47, 54, 0.8);
    background: transparent;
    border: 1px solid transparent; }
    .button--text:not(:disabled):hover, .b-form-button--cancel:not(:disabled):hover {
      background: transparent;
      border-color: transparent;
      text-decoration: underline; }
    .button--text:not(:disabled):active, .b-form-button--cancel:not(:disabled):active {
      background: transparent;
      border-color: transparent;
      text-decoration: underline; }
  .button--destructive, .b-form-button--remove, .b-danger__button {
    background: rgba(230, 76, 101, 0.8);
    border: 1px solid rgba(230, 76, 101, 0.8); }
    .button--destructive:not(:disabled):hover, .b-form-button--remove:not(:disabled):hover, .b-danger__button:not(:disabled):hover {
      background: rgba(230, 76, 101, 0.9);
      border-color: #E64C65; }
    .button--destructive:not(:disabled):active, .b-form-button--remove:not(:disabled):active, .b-danger__button:not(:disabled):active {
      background: #E64C65;
      border-color: #E64C65; }
    .button--destructive:disabled, .b-form-button--remove:disabled, .b-danger__button:disabled {
      opacity: 0.6; }
  .button--inline {
    width: auto;
    display: inline-block; }
    @media only screen and (min-width: 57.5625em) {
      .button--inline-l {
        width: auto;
        display: inline-block; } }
  .button--left {
    width: auto;
    float: left;
    margin-top: 15px; }
    @media only screen and (min-width: 57.5625em) {
      .button--left-l, .b-form-button--remove, .b-form-button--hide, .b-form-button--unhide {
        width: auto;
        float: left;
        margin-top: 15px; } }
  .button--right {
    width: auto;
    float: right;
    margin-top: 15px; }
    @media only screen and (min-width: 57.5625em) {
      .button--right-l, .b-form-button--save, .b-form-button--cancel {
        width: auto;
        float: right;
        margin-top: 15px; } }
  .button--small {
    font-size: 0.9em;
    border-radius: 3px;
    padding: 5px 16px; }
    @media only screen and (min-width: 57.5625em) {
      .button--small-l, .b-form-button--save, .b-form-button--cancel, .b-form-button--remove, .b-form-button--hide, .b-form-button--unhide {
        font-size: 0.9em;
        border-radius: 3px;
        padding: 5px 16px; } }

@keyframes rotateThis {
  from {
    transform: rotate(0deg);
    transform-origin: 50%; }
  to {
    transform: rotate(360deg);
    transform-origin: 50%; } }

@font-face {
  font-family: 'ubud';
  src: url("../fonts/ubud.eot?77451498");
  src: url("../fonts/ubud.eot?77451498#iefix") format("embedded-opentype"), url("../fonts/ubud.woff2?77451498") format("woff2"), url("../fonts/ubud.woff?77451498") format("woff"), url("../fonts/ubud.ttf?77451498") format("truetype"), url("../fonts/ubud.svg?77451498#ubud") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ubud';
    src: url('../font/ubud.svg?77451498#ubud') format('svg');
  }
}
*/
[class^="icon-"]:before, .b-loader:after, .b-danger:after, .b-modal:after, .app--initializing:after, .budget__label-category-settings, .budget__label-budget-settings, .document-general-settings__category-settings, .document-general-settings__budget-settings, .header-documents__link:before, [class*=" icon-"]:before {
  font-family: "ubud";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-music:before {
  content: '\e800'; }

/* '' */
.icon-heart:before {
  content: '\e801'; }

/* '' */
.icon-cog:before {
  content: '\e802'; }

/* '' */
.icon-comment:before {
  content: '\e803'; }

/* '' */
.icon-cup:before {
  content: '\e804'; }

/* '' */
.icon-doc:before, .header-documents__link:before {
  content: '\e805'; }

/* '' */
.icon-graduation-cap:before {
  content: '\e806'; }

/* '' */
.icon-cloud:before {
  content: '\e807'; }

/* '' */
.icon-globe:before {
  content: '\e808'; }

/* '' */
.icon-cd:before {
  content: '\e809'; }

/* '' */
.icon-desktop:before {
  content: '\e80a'; }

/* '' */
.icon-mobile:before {
  content: '\e80b'; }

/* '' */
.icon-lightbulb:before {
  content: '\e80c'; }

/* '' */
.icon-clock:before {
  content: '\e80d'; }

/* '' */
.icon-beaker:before {
  content: '\e80e'; }

/* '' */
.icon-truck:before {
  content: '\e80f'; }

/* '' */
.icon-money:before {
  content: '\e810'; }

/* '' */
.icon-food:before {
  content: '\e811'; }

/* '' */
.icon-shop:before {
  content: '\e812'; }

/* '' */
.icon-t-shirt:before {
  content: '\e813'; }

/* '' */
.icon-wallet:before {
  content: '\e814'; }

/* '' */
.icon-diamond:before {
  content: '\e815'; }

/* '' */
.icon-tv:before {
  content: '\e816'; }

/* '' */
.icon-location:before {
  content: '\e817'; }

/* '' */
.icon-tag:before {
  content: '\e818'; }

/* '' */
.icon-pencil:before {
  content: '\e819'; }

/* '' */
.icon-megaphone:before {
  content: '\e81a'; }

/* '' */
.icon-key:before {
  content: '\e81b'; }

/* '' */
.icon-database:before {
  content: '\e81c'; }

/* '' */
.icon-fire:before {
  content: '\e81d'; }

/* '' */
.icon-attach:before {
  content: '\e81e'; }

/* '' */
.icon-search:before {
  content: '\e81f'; }

/* '' */
.icon-paper-plane:before {
  content: '\e820'; }

/* '' */
.icon-trash:before {
  content: '\e821'; }

/* '' */
.b {
  /** Headlines **/
  /** Forms **/
  /** Loader **/
  /** Empty Message **/
  /** Danger Zone **/
  /** Modal */ }
  .b-headline-l1, .b-headline-l2 {
    display: none;
    margin: 3em 0 2em; }
    @media only screen and (min-width: 57.5625em) {
      .b-headline-l1, .b-headline-l2 {
        display: block; } }
    .b-headline-l1:first-child, .b-headline-l2:first-child {
      margin-top: 0; }
  .b-headline-l1 {
    font-size: 1.8em; }
    .b-headline-l1 + .b-headline-l2 {
      color: #666666;
      font-size: 1.1em;
      margin-top: -2.8em; }
  .b-headline-l2 {
    font-size: 1.4em; }
  .b-form {
    margin: 1em;
    overflow: hidden; }
    @media only screen and (min-width: 40.0625em) {
      .b-form {
        width: 90%;
        max-width: 46em;
        margin: 4em auto; } }
    .b-form-label {
      display: block;
      color: #4d4c4c;
      font-size: 14px;
      margin: 1em 0 0;
      padding: 4px 0; }
    .b-form-input, .budget-editor__goal, .b-form-select-wrap, .b-form-checkbox-wrap {
      appearance: none;
      display: block;
      width: 100%;
      padding: 9px;
      border: 1px solid #f2f5f9;
      outline: none;
      background: #f2f5f9; }
      .b-form-input--error, .b-form-select-wrap--error, .b-form-checkbox-wrap--error {
        border-color: #E64C65;
        background: #fce9ec; }
      .b-form-input + .b-form-label, .budget-editor__goal + .b-form-label, .b-form-select-wrap + .b-form-label, .b-form-checkbox-wrap + .b-form-label {
        margin-top: 1.4em; }
    .b-form-select-wrap {
      border: none; }
    .b-form-select {
      display: block;
      width: 100%;
      font: inherit;
      border: none;
      outline: none;
      background: transparent;
      -webkit-appearance: none; }
    .b-form-error {
      display: block;
      margin: 0.4em 9px 1.2em;
      color: #E64C65;
      font-size: 0.8em;
      max-width: 40em; }
    .b-form-info {
      display: block;
      margin: 0.4em 9px 1.2em;
      color: #666666;
      font-size: 0.8em;
      max-width: 40em; }
    .b-form-buttons {
      margin-top: 40px;
      overflow: hidden; }
    .b-form-button + .b-form-button {
      margin-top: 1em; }
      @media only screen and (min-width: 57.5625em) {
        .b-form-button + .b-form-button {
          margin-top: 0; } }
    .b-form-button--save {
      margin-top: 0; }
      @media only screen and (min-width: 57.5625em) {
        .b-form-button--save {
          margin-left: 10px; } }
    .b-form-button--cancel {
      opacity: 0.6;
      margin-top: 0; }
    .b-form-button--remove, .b-form-button--hide, .b-form-button--unhide {
      opacity: 0.8;
      margin-top: 0; }
    .b-form-button--unhide {
      opacity: 0.8; }
    .b-form-button--remove {
      margin-top: 0; }
  .b-loader, .b-danger, .b-modal {
    position: relative;
    min-height: 100px; }
    .b-loader:before, .b-danger:before, .b-modal:before, .b-loader:after, .b-danger:after, .b-modal:after {
      display: none;
      position: absolute; }
    .b-loader.loading:before, .loading.b-danger:before, .loading.b-modal:before, .b-loader.loading:after, .loading.b-danger:after, .loading.b-modal:after {
      display: block; }
    .b-loader:before, .b-danger:before, .b-modal:before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(34, 38, 44, 0.2);
      z-index: 15; }
    .b-loader--light:before, .b-danger:before, .b-modal:before {
      background: rgba(255, 255, 255, 0.4); }
    .b-loader--nobg:before {
      background: transparent; }
    .b-loader:after, .b-danger:after, .b-modal:after {
      content: '\e809';
      top: 50%;
      left: 50%;
      margin: -25px 0 0 -20px;
      width: 40px;
      height: 40px;
      z-index: 16;
      font-size: 2.5em;
      color: rgba(34, 38, 44, 0.6);
      animation: spin 1s linear infinite; }
  .b-empty-message {
    background: #f9fbfc;
    padding: 4em 2em;
    text-align: center;
    color: #807f7f; }
  .b-danger {
    margin: 4em 0 1em;
    padding: 1em;
    border: 0.1em solid #E64C65; }
    .b-danger + .b-danger {
      margin-top: 1em; }
    .b-danger__headline {
      margin: 0 0 0.2em;
      color: #4d4c4c;
      font-size: 1.2em; }
    .b-danger__message {
      color: rgba(51, 51, 51, 0.6);
      font-size: 0.9em; }
    .b-danger__button {
      margin-top: 15px; }
  .b-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 35;
    background: rgba(255, 255, 255, 0.96);
    transition: background 0.1s ease-out, backdrop-filter 0.1s ease-out 0.1s, max-height 0.3s ease-out; }
    @supports (backdrop-filter: grayscale(100%) blur(10px)) {
      .b-modal {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: grayscale(100%) blur(10px); } }
    .b-modal--hidden {
      background: rgba(255, 255, 255, 0);
      transition: background 0.3s ease-in 0.1s, backdrop-filter 0.1s ease-out 0.1s; }
      @supports (backdrop-filter: grayscale(100%) blur(10px)) {
        .b-modal--hidden {
          backdrop-filter: grayscale(0%); } }
    .b-modal__content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      max-width: 800px;
      max-height: 100%;
      margin: 0;
      padding: 5% 0 2em 0;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
      @media only screen and (min-width: 40.0625em) {
        .b-modal__content {
          width: 90%;
          max-width: 46em;
          margin: 0 auto;
          padding-right: 1em; } }
      .b-modal__content--fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        max-width: none;
        max-height: none;
        padding: 0;
        transform: translate3d(0, 0, 0);
        transition: opacity 0.3s ease-out; }
        @media only screen and (min-width: 40.0625em) {
          .b-modal__content--fullscreen {
            width: auto;
            max-width: none;
            padding: 0; } }
    .b-modal--hidden .b-modal__content {
      opacity: 0;
      transform: translate3d(-50%, -45%, 0);
      transition: opacity 0.1s ease-out, transform 0s ease-out 0.3s; }
    .b-modal.loading .b-modal__content {
      opacity: 0.6;
      pointer-events: none; }
    .b-modal .b-headline-l1, .b-modal .b-headline-l2 {
      display: block;
      margin: 3em 0 2em; }
      .b-modal .b-headline-l1:first-child, .b-modal .b-headline-l2:first-child {
        margin-top: 0; }
    .b-modal .b-headline-l1 {
      font-size: 1.4em; }
      @media only screen and (min-width: 57.5625em) {
        .b-modal .b-headline-l1 {
          font-size: 1.8em; } }
      .b-modal .b-headline-l1 + .b-headline-l2 {
        color: #666666;
        font-size: 1.1em;
        margin-top: -2.4em; }
        @media only screen and (min-width: 57.5625em) {
          .b-modal .b-headline-l1 + .b-headline-l2 {
            margin-top: -2.8em; } }
    .b-modal .b-headline-l2 {
      font-size: 1.2em; }
      @media only screen and (min-width: 57.5625em) {
        .b-modal .b-headline-l2 {
          font-size: 1.4em; } }
  .b-dl {
    font-size: 0.8em;
    padding: 0.5em 0 0; }
  .b-dt, .b-dl dt {
    float: left;
    text-align: right;
    width: 9em;
    padding: 0.5em 0; }
  .b-dd, .b-dl dd {
    padding: 0.5em 0 0.5em 10.5em; }
    .b-dd a:link, .b-dl dd a:link,
    .b-dd a:visited,
    .b-dl dd a:visited {
      color: inherit; }

/*
 :::  Reset
-------------------------------------------------- */
html {
  box-sizing: border-box;
  overflow-y: -moz-scrollbars-vertical; }

*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  touch-action: manipulation; }

article, aside, details, figcaption, figure,
footer, header, hgroup, main, nav, section, subhead, summary {
  display: block; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select {
  border-radius: 0; }

button {
  cursor: pointer;
  background: none;
  border: none;
  display: inline;
  outline: none; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.app {
  min-width: 320px;
  min-height: calc(env(safe-area-inset-top) + 100%);
  color: #333333; }
  .app--initializing:after {
    position: fixed;
    content: '\e809';
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;
    z-index: -1;
    font-size: 2.5em;
    color: rgba(34, 38, 44, 0.6);
    animation: spin 1s linear infinite; }
  .app__content {
    padding: calc(env(safe-area-inset-top, 0) + 60px) 0 calc(env(safe-area-inset-bottom) + 47px);
    width: 100%;
    height: auto;
    min-height: 100%; }
    @media only screen and (min-width: 57.5625em) {
      .app__content {
        padding: 80px 0 0 80px; } }
  .app_layout--page {
    max-width: 600px;
    margin: 40px auto;
    padding-bottom: 120px; }

.budget {
  display: flex;
  position: relative;
  min-height: 100%;
  font-size: 0.9em;
  user-select: none; }
  @media only screen and (min-width: 57.5625em) {
    .budget {
      font-size: 1em;
      padding-top: 1em; }
      .budget:before {
        content: '';
        position: fixed;
        top: 5em;
        background: #fff;
        background: linear-gradient(180deg, white 0%, white 80%, rgba(255, 255, 255, 0) 100%);
        z-index: 15;
        left: 80px;
        width: 14em;
        height: calc(7.8em); } }
  .budget__container {
    display: flex;
    padding: 0 0 2em;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    align-items: stretch;
    scrollbar-width: none;
    flex: 2; }
    .budget__container::-webkit-scrollbar {
      display: none; }
  .budget__labels {
    position: absolute;
    top: 5.8em;
    width: 35%;
    padding-left: env(safe-area-inset-left);
    z-index: 10; }
    @media only screen and (min-width: 57.5625em) {
      .budget__labels {
        float: left;
        position: static;
        top: 0;
        width: 15em;
        margin-top: calc(8.6em - 60px);
        background: #f9fbfc;
        border-right: 1em solid #FFFFFF;
        z-index: 18; } }
  .budget__label-category-header {
    position: relative;
    height: 48px; }
    @media only screen and (min-width: 57.5625em) {
      .budget__label-category-header {
        height: 60px; } }
  .budget__label-category:first-child .budget__label-category-header {
    background: #FFFFFF; }
  .budget__label-category-name, .budget-tips__category-name {
    display: block;
    position: relative;
    font-weight: bold;
    padding: 1.8em 0.6em 0.6em 0.6em;
    color: #595959;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: visible; }
    @media only screen and (min-width: 40.0625em) {
      .budget__label-category-name, .budget-tips__category-name {
        overflow: hidden; } }
  .budget__label-category-add {
    position: absolute;
    display: none;
    top: 1.35em;
    right: 1.8em;
    font-size: 1.2em;
    font-weight: bold;
    color: #737373; }
  .budget__label-category-settings {
    position: absolute;
    display: none;
    top: 1.9em;
    right: 0.6em;
    color: #737373; }
  @media only screen and (min-width: 57.5625em) {
    .budget__label-category-header:hover .budget__label-category-name, .budget__label-category-header:hover .budget-tips__category-name {
      padding-right: 3em; }
    .budget__label-category-header:hover .budget__label-category-add,
    .budget__label-category-header:hover .budget__label-category-settings {
      display: block; } }
  .budget__label-budget {
    display: block;
    height: 36px;
    position: relative;
    background: #eff3f8; }
    @media only screen and (min-width: 57.5625em) {
      .budget__label-budget {
        height: 40px; } }
    .budget__label-budget:nth-child(2n) {
      background: #f9fbfc; }
  .budget__label-budget-name {
    display: block;
    padding: 0.6em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .budget__label-budget-name--hidden {
      text-decoration: line-through; }
  .budget__label-budget-settings {
    position: absolute;
    display: none;
    top: 0.8em;
    right: 0.6em;
    color: #737373; }
  @media only screen and (min-width: 57.5625em) {
    .budget__label-budget:hover .budget__label-budget-name {
      padding-right: 2em; }
    .budget__label-budget:hover .budget__label-budget-settings {
      display: block; } }
  .budget__month {
    position: relative;
    background: #FFFFFF;
    scroll-snap-align: start;
    flex: 0 0 100%;
    flex-flow: column;
    justify-content: end;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out; }
    @media only screen and (min-width: 57.5625em) {
      .budget__month {
        flex: 0 0 calc(50% - 2em);
        background: #f9fbfc; }
        .budget__month + .budget__month {
          margin-left: 1em; } }
    @media only screen and (min-width: 80.0625em) {
      .budget__month {
        flex: 0 0 calc(33.3% - 1.5em); } }
    @media only screen and (min-width: 120.0625em) {
      .budget__month {
        flex: 0 0 26em; } }
    .budget__month--activated {
      opacity: 1; }
    .budget__month-header {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%);
      z-index: 16;
      height: 5.8em;
      text-align: center;
      transition: transform 0.3s ease-out, font-size 0.3s ease-in-out;
      transform: translate3d(0, 0, 0);
      background: #fff; }
      @media only screen and (min-width: 57.5625em) {
        .budget__month-header {
          transition: font-size 0.3s ease-in-out; } }
      @media only screen and (min-width: 57.5625em) {
        .budget__month-header--fixed {
          position: fixed;
          z-index: 16;
          transform: translate3d(0, calc(env(safe-area-inset-top, 0) + 80px), 0);
          font-size: 1em; } }
      .budget__month-header:not(.budget__month-header--fixed) {
        left: 0 !important; }
      .budget__month-header-m, .budget__month-header-y {
        display: none; }
      .budget__month-header-m {
        font-weight: bold;
        font-size: 28px;
        color: #666666; }
      .budget__month-header-y {
        font-size: 28px;
        font-weight: 100;
        margin-left: 0.1em;
        color: #737373; }
      @media only screen and (min-width: 57.5625em) {
        .budget__month-header {
          height: 8.6em;
          padding: 0.6em 0;
          background: #f9fbfc; }
          .budget__month-header-m, .budget__month-header-y {
            display: inline; } }
    .budget__month--current .budget__month-header:before {
      content: '';
      position: absolute;
      left: 3px;
      top: 0;
      right: 3px;
      height: 3px;
      z-index: 2;
      background: rgba(49, 36, 128, 0.5);
      border-radius: 0 0 5px 5px; }
      @media only screen and (min-width: 57.5625em) {
        .budget__month--current .budget__month-header:before {
          height: 5px;
          border: 1px solid #f9fbfc;
          border-top: none; } }
    .budget__month-category {
      padding-top: 48px; }
      @media only screen and (min-width: 57.5625em) {
        .budget__month-category {
          padding-top: 60px; } }
      .budget__month-category:first-child {
        margin-top: 5.8em;
        background: #FFFFFF; }
        @media only screen and (min-width: 57.5625em) {
          .budget__month-category:first-child {
            background: #f9fbfc;
            margin-top: 0;
            padding-top: 8.6em; } }
    .budget__month-portions {
      background: #f9fbfc; }
    .budget__month-portion {
      padding: 0.6em 1em 0.6em calc(35% + 1em);
      overflow: hidden;
      height: 36px; }
      @media only screen and (min-width: 57.5625em) {
        .budget__month-portion {
          padding-left: calc(45% + 1em); } }
      @media only screen and (min-width: 57.5625em) {
        .budget__month-portion {
          padding-left: 1em; } }
      @media only screen and (min-width: 57.5625em) {
        .budget__month-portion {
          height: 40px; } }
      .budget__month-portion:nth-child(2n+1) {
        background: #eff3f8; }
    .budget__month-budgeted, .budget__month-outflow, .budget__month-balance {
      display: block;
      float: left;
      text-align: right; }
    .budget__month-budgeted {
      width: 45%;
      background: transparent;
      border-bottom: 1px dotted rgba(51, 51, 51, 0.3);
      user-select: text;
      opacity: 0.1;
      pointer-events: none; }
      .budget__month-budgeted[disabled] {
        border-bottom: 0; }
      .budget__month-budgeted--visible {
        opacity: 1;
        pointer-events: auto; }
    .budget__month-outflow {
      display: none;
      opacity: 0.6; }
    .budget__month-balance {
      float: right;
      width: 55%; }
      .budget__month-balance--negative {
        color: #E64C65; }
      .budget__month-balance-item {
        display: none; }
        .budget__month-balance-item--active {
          display: inline-block; }
        .budget__month-balance-item--goal {
          width: calc(100% - 2.75em);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; }
    @media only screen and (min-width: 40.0625em) {
      .budget__month-budgeted {
        width: 28%; }
      .budget__month-outflow {
        display: block;
        width: 31%; }
      .budget__month-balance {
        width: 41%; } }
    @media only screen and (min-width: 40.0625em) {
      .budget__month-outflow, .budget__month-balance {
        font-size: 0.9em; } }
    .budget__month-progress, .budget-tips__budget-progress {
      float: right;
      display: inline-block;
      width: 2em;
      height: 0.3em;
      margin: 0.5em 0.25em 0 0.5em;
      padding: 1px;
      background: rgba(42, 47, 54, 0.2);
      border-radius: 0.15em; }
      .budget__month-progress--hidden {
        display: none; }
      .budget__month-progress--active, .budget-tips__budget-progress--active {
        background: #2A2F36; }
      .budget__month-progress--negative, .budget-tips__budget-progress--negative {
        background: #E64C65; }
      .budget__month-progress--complete, .budget-tips__budget-progress--complete {
        background: rgba(2, 133, 94, 0.8); }
      .budget__month-progress-bar, .budget-tips__budget-progress-bar {
        display: none;
        background: #EBF0F6;
        height: 100%;
        border-radius: 4px;
        transition: width 0.3s ease-out; }
      .budget__month-progress--active .budget__month-progress-bar, .budget-tips__budget-progress--active .budget__month-progress-bar, .budget__month-progress--active .budget-tips__budget-progress-bar, .budget-tips__budget-progress--active .budget-tips__budget-progress-bar {
        display: block; }
      .budget__month-progress--negative .budget__month-progress-bar, .budget-tips__budget-progress--negative .budget__month-progress-bar, .budget__month-progress--negative .budget-tips__budget-progress-bar, .budget-tips__budget-progress--negative .budget-tips__budget-progress-bar {
        display: none; }
      .budget__month-progress--complete .budget__month-progress-bar, .budget-tips__budget-progress--complete .budget__month-progress-bar, .budget__month-progress--complete .budget-tips__budget-progress-bar, .budget-tips__budget-progress--complete .budget-tips__budget-progress-bar {
        background: rgba(37, 251, 187, 0.5); }
  .budget__stats {
    cursor: pointer;
    user-select: none; }
    .budget__stats-page {
      display: none;
      height: 4.6em;
      padding-top: 1.2em;
      overflow: hidden; }
      .budget__stats-page--active {
        display: block; }
      @media only screen and (min-width: 57.5625em) {
        .budget__stats-page {
          height: 3.6em;
          padding-top: 0.4em; } }
    .budget__stats-label, .budget__stats-value {
      display: block;
      float: left;
      width: 50%; }
    .budget__stats-label {
      padding: 0;
      font-size: 0.8em;
      color: #666666; }
    .budget__stats-value {
      margin-top: -1px;
      font-size: 1.8em;
      font-weight: bold;
      color: #4d4c4c; }
      .budget__stats-value--warn {
        color: #e01f3f; }
    .budget__stats-page--single .budget__stats-label,
    .budget__stats-page--single .budget__stats-value {
      width: 100%; }
    .budget__stats-indicator {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: rgba(42, 47, 54, 0.2); }
      .budget__stats-indicator + .budget__stats-indicator {
        margin-left: 4px; }
      .budget__stats-indicator--active {
        background: rgba(42, 47, 54, 0.6); }
  .budget__autofill {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.5em;
    height: 3.5em;
    overflow: hidden; }
    .budget__autofill-circle:after {
      content: '';
      display: block;
      position: absolute;
      width: 4em;
      height: 4em;
      top: -1.5em;
      right: -2.0em;
      transform: translate3d(0, 0, 0);
      transition: transform ease-out 0.3s;
      background: #EBF0F6;
      border-radius: 50%;
      z-index: 1; }
    .budget__autofill-circle:before {
      position: absolute;
      top: 0.6em;
      right: 0.2em;
      z-index: 2;
      color: #a5bbd6;
      transition: transform ease-out 0.3s; }
    @media only screen and (min-width: 57.5625em) {
      .budget__autofill:hover .budget__autofill-circle:after {
        transform: translate3d(-0.5em, 0.5em, 0);
        background: #d9e3ee; }
      .budget__autofill:hover .budget__autofill-circle:before {
        transform: scale3d(1.2, 1.2, 1.2) translate3d(0, 0.2em, 0);
        color: #81a1c7; } }

.budget-editor__goal {
  position: relative; }
  .budget-editor__goal:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 32px;
    width: 1px;
    background: rgba(51, 51, 51, 0.1); }
  .budget-editor__goal-input {
    float: right;
    appearance: none;
    display: block;
    width: calc(100% - 35px);
    border: 1px solid #f2f5f9;
    outline: none;
    background: #f2f5f9; }
    .budget-editor__goal-input--empty {
      opacity: 0.4; }

@media only screen and (min-width: 57.5625em) {
  .budget-editor__remove:not(:first-child) {
    margin-left: 1em; } }

@media only screen and (min-width: 57.5625em) {
  .budget-editor__unhide {
    margin-left: 1em; } }

.budget-tips__content {
  display: flex;
  padding: 0;
  width: 100%;
  height: calc(100% - env(safe-area-inset-top, 0));
  overflow: hidden;
  margin-top: env(safe-area-inset-top, 0);
  flex-direction: column; }
  @media only screen and (min-width: 40.0625em) {
    .budget-tips__content {
      max-height: 60%; } }

.budget-tips__categories {
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }

.budget-tips__budget {
  display: block;
  height: 36px;
  position: relative; }
  @media only screen and (min-width: 57.5625em) {
    .budget-tips__budget {
      height: 40px; } }
  .budget-tips__budget-budget, .budget-tips__budget-before, .budget-tips__budget-selector, .budget-tips__budget-after, .budget-tips__budget-balance {
    display: block;
    float: left;
    height: 100%;
    padding: 0.6em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background: #eff3f8; }
  .budget-tips__budget:nth-child(2n) .budget-tips__budget-budget,
  .budget-tips__budget:nth-child(2n) .budget-tips__budget-before,
  .budget-tips__budget:nth-child(2n) .budget-tips__budget-selector,
  .budget-tips__budget:nth-child(2n) .budget-tips__budget-after,
  .budget-tips__budget:nth-child(2n) .budget-tips__budget-balance {
    background: #f9fbfc; }
  .budget-tips__budget-before, .budget-tips__budget-selector, .budget-tips__budget-after, .budget-tips__budget-balance {
    margin-left: 2px; }
  .budget-tips__budget-budget {
    width: calc(34% - 2px); }
    @media only screen and (min-width: 40.0625em) {
      .budget-tips__budget-budget {
        width: calc(28% - 2px); } }
  .budget-tips__budget-before, .budget-tips__budget-after {
    width: calc(28% - 2px);
    text-align: right; }
    @media only screen and (min-width: 40.0625em) {
      .budget-tips__budget-before, .budget-tips__budget-after {
        width: calc(17% - 2px); } }
  .budget-tips__budget-after {
    color: rgba(51, 51, 51, 0.4); }
    .budget-tips__budget-after--changed {
      color: #333333; }
  .budget-tips__budget-selector {
    width: 10%;
    text-align: center;
    overflow: hidden; }
    .budget-tips__budget-selector-arrow {
      display: block;
      margin-top: -0.1em;
      font-size: 1.2em;
      opacity: 0.4; }
      .budget-tips__budget-selector-arrow--selected {
        opacity: 1; }
  .budget-tips__budget-balance {
    display: none;
    width: calc(28% - 2px);
    text-align: right; }
    @media only screen and (min-width: 40.0625em) {
      .budget-tips__budget-balance {
        display: block; } }
    .budget-tips__budget-balance--negative {
      color: #E64C65; }

.budget-tips__footer {
  background: rgba(255, 255, 255, 0.95);
  z-index: 50; }

.budget-tips__buttons {
  padding: 1em 1em calc(env(safe-area-inset-bottom) + 1em) 1em; }

.budget-tips__rules {
  display: flex;
  padding: 1em 0 1em 1em;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  align-items: stretch;
  flex: 2;
  scrollbar-width: auto; }
  @media only screen and (min-width: 40.0625em) {
    .budget-tips__rules {
      scroll-snap-type: x mandatory;
      padding-left: 0; } }
  .budget-tips__rules::-webkit-scrollbar {
    display: none; }

.budget-tips__rule {
  position: relative;
  scroll-snap-align: center;
  flex: 0 0 90%;
  flex-flow: column;
  justify-content: end;
  border-right: 1em solid transparent;
  opacity: 0.3;
  transition: opacity 0.3s ease-out; }
  @media only screen and (min-width: 40.0625em) {
    .budget-tips__rule {
      justify-content: end;
      flex: 1 0 calc(65% - 2em);
      background: #f9fbfc; }
      .budget-tips__rule:last-child {
        border-right: 0; } }
  .budget-tips__rule--active {
    opacity: 1; }
  .budget-tips__rule-button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.6em;
    transition: opacity 0.3s ease-in-out;
    background: #FFFFFF;
    border: 1px solid rgba(42, 47, 54, 0.4);
    text-align: left; }
  .budget-tips__rule-title {
    display: block;
    font-weight: bold;
    font-size: 0.9em; }
  .budget-tips__rule-description {
    display: block;
    font-size: 0.9em; }

.document-settings {
  margin: 1em; }
  @media only screen and (min-width: 57.5625em) {
    .document-settings {
      width: 90%;
      margin: 4em auto; } }
  .document-settings__list, .document-settings-account-add__types {
    margin: 0; }
    @media only screen and (min-width: 40.0625em) {
      .document-settings__list, .document-settings-account-add__types {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin: -0.5em; } }
    @media only screen and (min-width: 57.5625em) {
      .document-settings__list, .document-settings-account-add__types {
        margin: 0 -0.5em; } }
  .document-settings__item + .document-settings__item, .document-settings-account-add__type + .document-settings__item, .document-settings__item + .document-settings-account-add__type, .document-settings-account-add__type + .document-settings-account-add__type {
    margin-top: 1em; }
  @media only screen and (min-width: 40.0625em) {
    .document-settings__item, .document-settings-account-add__type {
      width: calc(50% - 1em);
      margin: 0.5em; }
      .document-settings__item + .document-settings__item, .document-settings-account-add__type + .document-settings__item, .document-settings__item + .document-settings-account-add__type, .document-settings-account-add__type + .document-settings-account-add__type {
        margin-top: 0.5em; } }
  @media only screen and (min-width: 75.0625em) {
    .document-settings__item, .document-settings-account-add__type {
      width: calc(25% - 1em);
      max-width: 24em; } }
  .document-settings__link, .document-settings-account-add__type-button {
    display: block;
    width: 100%;
    background: #EBF0F6;
    text-decoration: none;
    padding: 2em;
    min-height: 100%; }
    .document-settings__link:before, .document-settings-account-add__type-button:before {
      display: block;
      width: 100%;
      font-size: 2em;
      text-align: center;
      margin: 0 0 0.6em;
      color: #807f7f; }
      @media only screen and (min-width: 57.5625em) {
        .document-settings__link:before, .document-settings-account-add__type-button:before {
          font-size: 2.4em; } }
    @media only screen and (min-width: 57.5625em) {
      .document-settings__link:hover, .document-settings-account-add__type-button:hover {
        background: #e4ebf3; }
        .document-settings__link:hover:before, .document-settings-account-add__type-button:hover:before {
          color: #666666; }
      .document-settings__link:active, .document-settings-account-add__type-button:active {
        background: #dde5f0; }
        .document-settings__link:active:before, .document-settings-account-add__type-button:active:before {
          color: #595959; } }
  .document-settings__title, .document-settings-account-add__type-title {
    display: block; }
  .document-settings__description, .document-settings-account-add__type-description {
    margin-top: -0.2em;
    font-size: 0.8em;
    color: #666666; }

.document-settings-account__item + .document-settings-account__item {
  margin-top: 1em; }

.document-settings-account__link {
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 10px;
  background: #f2f5f9; }

.document-settings-account__name {
  display: block;
  font-size: 1.1em; }

.document-settings-account__balance {
  float: right;
  line-height: 3em;
  color: #666666; }

.document-settings-account__meta {
  font-size: 0.9em;
  color: #666666; }

.document-settings-account-add {
  overflow: visible; }
  @media only screen and (min-width: 40.0625em) {
    .document-settings-account-add__type:last-child {
      flex-grow: 2; } }
  @media only screen and (min-width: 75.0625em) {
    .document-settings-account-add__type {
      width: calc(33.33% - 1em); } }

.document-general-settings__category {
  padding: 1em;
  background: #eff3f8; }
  .document-general-settings__category:not(:first-child) {
    margin-top: 1em; }
  .document-general-settings__category-header {
    width: 100%;
    text-align: inherit;
    position: relative;
    padding: 1em 0.6em; }
  @media only screen and (min-width: 57.5625em) {
    .document-general-settings__category {
      padding: 0.6em; } }
  .document-general-settings__category:first-child .document-general-settings__category-header {
    padding-top: 0.6em; }
  .document-general-settings__category-name {
    display: block;
    position: relative;
    font-weight: bold;
    color: #595959;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: visible; }
    @media only screen and (min-width: 40.0625em) {
      .document-general-settings__category-name {
        overflow: hidden; } }
  .document-general-settings__category-settings {
    position: absolute;
    display: none;
    top: 1.2em;
    right: 0.6em;
    color: #737373; }
  .document-general-settings__category:first-child .document-general-settings__category-settings {
    top: 0.8em; }
  @media only screen and (min-width: 57.5625em) {
    .document-general-settings__category-header:hover .document-general-settings__category-name {
      padding-right: 2em; }
    .document-general-settings__category-header:hover .document-general-settings__category-settings {
      display: block; } }

.document-general-settings__budget {
  display: block;
  position: relative; }
  .document-general-settings__budget + .document-general-settings__budget {
    border-top: 1px solid #d9e3ee; }
  .document-general-settings__budget-button {
    width: 100%;
    text-align: inherit;
    background: #f2f5f9; }
    @media only screen and (min-width: 57.5625em) {
      .document-general-settings__budget-button:hover {
        background: #f9fbfc; } }
  .document-general-settings__budget-name {
    display: block;
    padding: 0.8em 0.6em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    @media only screen and (min-width: 57.5625em) {
      .document-general-settings__budget-name {
        padding: 0.6em; } }
  .document-general-settings__budget--hidden {
    display: none;
    text-decoration: line-through; }
  .document-general-settings__budget-settings {
    position: absolute;
    display: none;
    top: 0.8em;
    right: 0.6em;
    color: #737373; }
  @media only screen and (min-width: 57.5625em) {
    .document-general-settings__budget:hover .document-general-settings__budget-name {
      padding-right: 2em; }
    .document-general-settings__budget:hover .document-general-settings__budget-settings {
      display: block; } }

.document-general-settings__categories--with-hidden .document-general-settings__budget--hidden {
  display: block; }

.document-general-settings__add, .document-general-settings__toggle-hidden-button {
  display: block;
  width: 100%;
  margin-top: 1em;
  padding: 1em;
  background: #eff3f8;
  text-align: center; }
  @media only screen and (min-width: 57.5625em) {
    .document-general-settings__add:hover, .document-general-settings__toggle-hidden-button:hover {
      background: #EBF0F6; } }

.document-general-settings__toggle-hidden-button {
  font-size: 0.8em;
  color: rgba(51, 51, 51, 0.6); }

.document-general-settings__add-buttons {
  overflow: hidden; }

.document-general-settings__add {
  float: left;
  width: 50%; }
  .document-general-settings__add-plus {
    display: block;
    font-size: 4em;
    line-height: 1em;
    color: rgba(51, 51, 51, 0.4); }
  .document-general-settings__add-text {
    display: block;
    margin: 0 0 1em;
    font-size: 0.8em;
    color: rgba(51, 51, 51, 0.8); }

.document-settings-permissions__user + .document-settings-permissions__user {
  margin-top: 1em; }

.document-settings-permissions__email {
  margin-left: 0.6em; }

.document-settings-permissions__admin {
  margin-left: 1em;
  padding: 0 1em;
  line-height: 1.4em;
  font-size: 0.8em;
  font-weight: bold;
  color: #807f7f;
  border: 1px solid #b3b2b2;
  border-radius: 4px; }

.document-settings-permissions__info {
  background: #f9fbfc;
  padding: 4em 2em;
  text-align: center; }
  .document-settings-permissions__info-icon {
    display: block;
    text-align: center;
    color: #b3b2b2;
    font-size: 3em; }
  .document-settings-permissions__info-text {
    display: block;
    margin: 1.4em auto 0;
    max-width: 28em;
    text-align: center;
    color: #666666; }

.document-settings-plugin__item + .document-settings-plugin__item {
  margin-top: 1em; }

.document-settings-plugin__link {
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 10px;
  background: #f2f5f9; }

.document-settings-plugin__name {
  display: block;
  font-size: 1.1em; }

.document-settings-plugin__status, .document-settings-plugin-details__status {
  float: right;
  margin-top: 1em;
  padding: 0 1em;
  line-height: 1.6em;
  font-size: 0.8em;
  font-weight: bold;
  color: #807f7f;
  border: 1px solid #b3b2b2;
  border-radius: 4px; }
  .document-settings-plugin__status[data-status="configuration"], .document-settings-plugin-details__status[data-status="configuration"] {
    color: rgba(243, 129, 73, 0.8);
    border-color: rgba(243, 129, 73, 0.6); }
  .document-settings-plugin__status[data-status="ready"], .document-settings-plugin-details__status[data-status="ready"] {
    color: rgba(3, 183, 129, 0.8);
    border-color: rgba(3, 183, 129, 0.6); }
  .document-settings-plugin__status[data-status="error"], .document-settings-plugin-details__status[data-status="error"] {
    color: rgba(230, 76, 101, 0.8);
    border-color: rgba(230, 76, 101, 0.6); }

.document-settings-plugin__meta {
  font-size: 0.9em;
  color: #666666; }

.document-settings-plugin-add {
  overflow: visible; }
  .document-settings-plugin-add__results {
    margin-top: 3em;
    padding: 1em 0 0; }
    .document-settings-plugin-add__results--empty {
      background: #EBF0F6;
      padding: 1em 1em 2em 1em; }
    .document-settings-plugin-add__results--empty .document-settings-plugin-add__animation--hidden:before {
      color: #c8d6e6; }
  .document-settings-plugin-add__animation {
    position: relative;
    display: block;
    width: 1em;
    height: 1em;
    font-size: 4em;
    margin: 0 auto;
    overflow: hidden; }
    .document-settings-plugin-add__animation:before {
      display: block;
      margin: 0.1em 0 0 0.1em;
      font-size: 0.8em;
      color: #c8d6e6; }
    .document-settings-plugin-add__animation--hidden:before {
      color: transparent; }
    .document-settings-plugin-add__animation--active:before {
      animation: search 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) both; }
  .document-settings-plugin-add__empty {
    padding-top: 1em;
    text-align: center; }
    .document-settings-plugin-add__empty-title {
      display: block;
      text-align: center; }
    .document-settings-plugin-add__empty-message {
      display: block;
      max-width: 24em;
      margin: 1em auto 2em;
      text-align: center;
      font-size: 0.8em;
      color: #666666; }
  .document-settings-plugin-add__list {
    margin-bottom: 4em; }
  .document-settings-plugin-add__item + .document-settings-plugin-add__item {
    margin-top: 1em; }
  .document-settings-plugin-add__button {
    display: block;
    text-decoration: none;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: #f2f5f9; }
  .document-settings-plugin-add__name, .document-settings-plugin-add__meta {
    display: block;
    max-width: calc(100% - 8em); }
  .document-settings-plugin-add__name {
    font-size: 1.1em;
    text-align: left; }
  .document-settings-plugin-add__meta {
    font-size: 0.9em;
    color: #666666; }
  .document-settings-plugin-add__install {
    float: right;
    margin-top: 1em;
    padding: 0 1em;
    line-height: 1.6em;
    font-size: 0.8em;
    font-weight: bold;
    color: rgba(3, 183, 129, 0.8);
    border: 1px solid rgba(3, 183, 129, 0.6);
    border-radius: 4px; }

@keyframes search {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 0 100%; }
  10% {
    transform: rotate(2deg); }
  20%,
  40%,
  60% {
    transform: rotate(-4deg); }
  30%,
  50%,
  70% {
    transform: rotate(4deg); }
  80% {
    transform: rotate(-2deg); }
  90% {
    transform: rotate(2deg); } }

.document-settings-plugin-add-details {
  overflow: visible; }
  .document-settings-plugin-add-details__header {
    overflow: hidden;
    margin-bottom: 2em; }
  .document-settings-plugin-add-details__meta {
    width: 33%;
    float: left;
    color: #666666;
    font-size: 0.8em; }
    .document-settings-plugin-add-details__meta:nth-child(6) {
      display: none; }
    .document-settings-plugin-add-details__meta:first-child:last-child {
      display: none; }
    @media only screen and (min-width: 20.0625em) {
      .document-settings-plugin-add-details__meta {
        width: 25%;
        font-size: 1em; }
        .document-settings-plugin-add-details__meta:nth-child(6) {
          display: block; } }
  .document-settings-plugin-add-details__meta-value {
    display: block;
    margin-top: 1.4em;
    width: calc(100% - 1em);
    font-size: 1.4em;
    font-weight: bold; }
    .document-settings-plugin-add-details__meta-value + .document-settings-plugin-add-details__meta-value {
      float: left; }
  .document-settings-plugin-add-details__meta-key {
    display: block;
    width: calc(100% - 1em);
    font-size: 0.8em; }
  .document-settings-plugin-add-details__info-wrap, .document-settings-plugin-details__info-wrap {
    overflow: hidden;
    margin: 0 0 4em; }
    @media only screen and (min-width: 40.0625em) {
      .document-settings-plugin-add-details__info-wrap, .document-settings-plugin-details__info-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
  .document-settings-plugin-add-details__info, .document-settings-plugin-details__info {
    color: #666666; }
    @media only screen and (min-width: 40.0625em) {
      .document-settings-plugin-add-details__info, .document-settings-plugin-details__info {
        flex: 0 0 auto;
        flex-basis: 50%; } }
    .document-settings-plugin-add-details__info a, .document-settings-plugin-details__info a,
    .document-settings-plugin-add-details__info a:link,
    .document-settings-plugin-details__info a:link,
    .document-settings-plugin-add-details__info a:visited,
    .document-settings-plugin-details__info a:visited {
      text-decoration: none; }
  .document-settings-plugin-add-details__info-header, .document-settings-plugin-details__info-header {
    font-size: 1em;
    font-weight: bold;
    margin: 2em 0 0; }
  .document-settings-plugin-add-details__feature + .document-settings-plugin-add-details__feature, .document-settings-plugin-details__feature + .document-settings-plugin-add-details__feature, .document-settings-plugin-add-details__feature + .document-settings-plugin-details__feature, .document-settings-plugin-details__feature + .document-settings-plugin-details__feature {
    padding: 0.6em 0 0; }
  .document-settings-plugin-add-details__feature:last-child, .document-settings-plugin-details__feature:last-child {
    padding-bottom: 0.5em; }
  .document-settings-plugin-add-details__info-bullet:before, .document-settings-plugin-details__info-bullet:before {
    content: '';
    display: inline-block;
    position: relative;
    top: -2px;
    width: 0.4em;
    height: 0.4em;
    margin: 0 0.4em 0 0;
    background: #2A2F36;
    border-radius: 50%; }
  .document-settings-plugin-add-details__info-bullet--ok:before, .document-settings-plugin-details__info-bullet--ok:before {
    background: #03B781; }
  .document-settings-plugin-add-details__info-bullet--warn:before, .document-settings-plugin-details__info-bullet--warn:before {
    background: #F38149; }
  .document-settings-plugin-add-details__info-bullet--err:before, .document-settings-plugin-details__info-bullet--err:before {
    background: #E64C65; }
  .document-settings-plugin-add-details__warning {
    background: #E64C65;
    margin: 4em -1em0;
    padding: 1em;
    color: rgba(255, 255, 255, 0.8);
    text-align: center; }
    .document-settings-plugin-add-details__warning-icon {
      display: block;
      font-size: 2em; }
    .document-settings-plugin-add-details__warning-text {
      display: block;
      margin: 1em auto;
      padding: 0 2em;
      font-size: 0.9em; }
  .document-settings-plugin-add-details__install-button {
    display: block;
    width: 100%;
    max-width: 18em;
    margin: 2em auto 0;
    padding: 15px;
    background: transparent;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-decoration: none; }
  .document-settings-plugin-add-details__terms {
    display: inline-block;
    width: 100%;
    max-width: 20em;
    margin: 0.8em auto 1em;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none; }

.document-settings-plugin-add-setup__loader {
  display: block;
  padding: 4em 0; }
  .document-settings-plugin-add-setup__loader-icon {
    display: block;
    width: 40px;
    margin: 0 auto 1em; }
    .document-settings-plugin-add-setup__loader-icon:before {
      margin: 0;
      width: 40px;
      height: 40px;
      z-index: 51;
      font-size: 2.5em;
      color: rgba(34, 38, 44, 0.6);
      animation: spin 1s linear infinite; }
  .document-settings-plugin-add-setup__loader-text {
    display: block;
    text-align: center;
    font-size: 0.9em;
    color: rgba(34, 38, 44, 0.6); }

.document-settings-plugin-details__status {
  float: none;
  margin-left: 1.4em;
  padding: 0 0.6em; }

.document-settings-plugin-details__error {
  position: relative;
  margin-top: 1em;
  padding: 1em;
  background: #2A2F36; }
  .document-settings-plugin-details__error-method {
    float: right;
    color: rgba(255, 255, 255, 0.2);
    font-weight: bold;
    font-size: 0.8em; }
  .document-settings-plugin-details__error-message {
    display: block;
    padding: 1em;
    border-left: 0.3em solid #E64C65;
    color: #FFFFFF;
    overflow: auto;
    white-space: pre-wrap;
    width: 100%; }
  .document-settings-plugin-details__error:empty {
    display: none; }

.document-settings-plugin-details__info-wrap {
  margin: 2em 0; }

.error {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 40;
  background: rgba(230, 76, 101, 0.85);
  backdrop-filter: grayscale(100%) blur(10px); }
  .error__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 800px;
    max-height: 100%;
    padding: 5% 0 20% 0;
    transform: translate(-50%, -50%);
    color: #EBF0F6;
    text-align: center;
    overflow: auto; }
  .error__icon {
    font-size: 2.8em; }
    @media only screen and (min-width: 57.5625em) {
      .error__icon {
        font-size: 4.6em; } }
  .error__headline {
    font-size: 1.4em;
    margin-top: 1em; }
    @media only screen and (min-width: 57.5625em) {
      .error__headline {
        font-size: 2.2em; } }
  .error__message {
    font-size: 0.9em;
    margin: 0.8em 0 2.4em; }
    @media only screen and (min-width: 57.5625em) {
      .error__message {
        margin: 0.8em 0 4.8em; } }
  .error__info {
    margin-top: 1em;
    padding: 1em;
    background: rgba(42, 47, 54, 0.8);
    text-align: left; }
    .error__info-label {
      display: block;
      font-size: 0.6em;
      color: rgba(235, 240, 246, 0.6); }
    .error__info-content {
      font-family: monospace;
      white-space: pre;
      overflow: auto; }

.first-setup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .first-setup__gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, white 0%, #EBF0F6 90%, #EBF0F6 100%); }
  .first-setup__form {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 310px;
    margin: -155px 0 0 -150px; }
    .first-setup__form-close {
      color: #c8d6e6;
      font-size: 32px; }
    .first-setup__form-box {
      background: #EBF0F6;
      border: 4px solid #c8d6e6;
      border-radius: 6px;
      margin-bottom: 15px;
      padding: 0 5px;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px; }
      .first-setup__form-box--error {
        animation: error 0.85s cubic-bezier(0.35, 0.07, 0.2, 0.97) both; }
      .first-setup__form-box--hidden {
        display: none; }
    .first-setup__form-input {
      display: block;
      box-sizing: border-box;
      width: 100%;
      padding: 12px 10px;
      background: none;
      border: none;
      outline: none; }
      .first-setup__form-input::placeholder {
        opacity: .6; }
      .first-setup__form-input--error {
        background: rgba(230, 76, 101, 0.1); }
      .first-setup__form-input + .first-setup__form-input {
        border-top: 1px solid #c8d6e6; }
    .first-setup__form-error {
      display: block;
      margin: 1em 0;
      color: #E64C65;
      font-weight: bold;
      font-size: 0.7em; }
      .first-setup__form-error a:link,
      .first-setup__form-error a:visited {
        display: inline-block;
        margin-bottom: -4px;
        color: inherit; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20; }
  .header__list {
    display: block;
    background: #2A2F36;
    overflow: hidden; }
  .header__item {
    position: relative;
    display: block;
    margin: calc(env(safe-area-inset-top) + 10px) env(safe-area-inset-right) 10px env(safe-area-inset-left); }
    @media only screen and (min-width: 57.5625em) {
      .header__item {
        margin: 0; } }
    .header__item--documents {
      float: left;
      margin-left: 10px;
      z-index: 4; }
      @media only screen and (min-width: 57.5625em) {
        .header__item--documents {
          margin-left: 0; } }
    .header__item--settings {
      float: right;
      margin-right: 10px;
      width: 40px;
      z-index: 4; }
      @media only screen and (min-width: 57.5625em) {
        .header__item--settings {
          width: 80px;
          margin-right: 0; } }
    @media only screen and (min-width: 57.5625em) {
      .header__item--settings.header__item--avatar {
        width: 160px; } }
    .header__item-icon {
      display: block;
      width: 40px;
      height: 40px;
      background: #383f49;
      font-family: 'ubud';
      font-style: normal;
      font-weight: normal;
      speak: none;
      font-size: 1.3em;
      font-variant: normal;
      text-transform: none;
      text-align: center;
      line-height: 40px;
      text-decoration: none;
      color: #FFFFFF;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border-radius: 50%; }
      @media only screen and (min-width: 57.5625em) {
        .header__item-icon {
          width: 80px;
          height: 80px;
          line-height: 80px;
          border-radius: 0;
          font-size: 1.8em; }
          .header__item-icon:hover, .header__item-icon--active {
            background: #343a43; }
          .header__item-icon:active {
            background: #22262C; } }
      .header__item-icon--settings {
        float: left; }
        @media only screen and (min-width: 57.5625em) {
          .header__item-icon--settings {
            display: block;
            width: 80px; } }
    .header__item--avatar .header__item-icon--settings {
      display: none; }
      @media only screen and (min-width: 57.5625em) {
        .header__item--avatar .header__item-icon--settings {
          display: block; } }
    .header__item-avatar {
      position: relative;
      display: none;
      float: left;
      width: 40px;
      height: 40px;
      background: #343a43;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer; }
      @media only screen and (min-width: 57.5625em) {
        .header__item-avatar {
          width: 80px;
          height: 80px;
          border-radius: 0; } }
      .header__item-avatar img {
        display: block;
        width: 100%;
        height: 100%; }
    .header__item--avatar .header__item-avatar {
      display: block; }
    .header__item--connection {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: env(safe-area-inset-top) 0 0;
      z-index: 5;
      background: #03B781;
      transform: translate(0, -100%);
      transition: transform ease-in 0.3s 0.8s, background ease-in-out 0.2s, opacity ease-in 0.1s 0.8s; }
      .header__item--connection-disconnected, .header__item--connection-connecting, .header__item--connection-connected, .header__item--connection-authenticating {
        transform: translate(0, 0);
        transition: transform ease-out 0.3s, background ease-in-out 0.2s, opacity ease-out 0.1s 0.2s; }
      .header__item--connection-disconnected,
      .header__item--connection-disconnected > .header__connection, .header__item--connection-connecting,
      .header__item--connection-connecting > .header__connection {
        background: #E64C65; }
        .header__item--connection-disconnected .header__connection:before,
        .header__item--connection-disconnected > .header__connection .header__connection:before, .header__item--connection-connecting .header__connection:before,
        .header__item--connection-connecting > .header__connection .header__connection:before {
          background: #E64C65;
          box-shadow: 0 0 0 #E64C65; }
        @media only screen and (min-width: 57.5625em) {
          .header__item--connection-disconnected,
          .header__item--connection-disconnected > .header__connection, .header__item--connection-connecting,
          .header__item--connection-connecting > .header__connection {
            background: transparent; } }
      @media only screen and (min-width: 57.5625em) {
        .header__item--connection-authenticating > .header__connection:before {
          animation: pulse 1s infinite; } }
      .header__item--connection-connected,
      .header__item--connection-connected > .header__connection, .header__item--connection-authenticating,
      .header__item--connection-authenticating > .header__connection {
        background: #F38149; }
        .header__item--connection-connected .header__connection:before,
        .header__item--connection-connected > .header__connection .header__connection:before, .header__item--connection-authenticating .header__connection:before,
        .header__item--connection-authenticating > .header__connection .header__connection:before {
          background: #F38149;
          box-shadow: 0 0 0 #F38149; }
        @media only screen and (min-width: 57.5625em) {
          .header__item--connection-connected,
          .header__item--connection-connected > .header__connection, .header__item--connection-authenticating,
          .header__item--connection-authenticating > .header__connection {
            background: transparent; } }
      @media only screen and (min-width: 57.5625em) {
        .header__item--connection-authenticating > .header__connection:before {
          animation: pulse 0.5s infinite; } }
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        .header__item--connection {
          left: 83px;
          right: 83px;
          bottom: auto;
          padding: 30px 0 0;
          border-bottom-right-radius: 22px;
          border-bottom-left-radius: 22px;
          overflow: hidden; } }
      @media only screen and (min-width: 57.5625em) {
        .header__item--connection {
          left: 80px;
          right: auto;
          padding: 0 30px;
          background: transparent;
          opacity: 0; }
          .header__item--connection-disconnected, .header__item--connection-connecting, .header__item--connection-connected, .header__item--connection-authenticating {
            background: 0;
            opacity: 1; }
          .header__item--connection-disconnected,
          .header__item--connection-disconnected > .header__connection {
            background: none; } }
    .header__item--title {
      position: absolute;
      top: 0;
      left: 40px;
      right: 40px;
      padding: 0 20px;
      color: #FFFFFF;
      margin: calc(env(safe-area-inset-top) + 20px) 0 20px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media only screen and (min-width: 57.5625em) {
        .header__item--title {
          display: none; } }
    .header__item--indicator:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0.9em;
      height: 0.9em;
      background: #E64C65;
      border-radius: 50%;
      animation: pulse 2s infinite; }
      @media only screen and (min-width: 57.5625em) {
        .header__item--indicator:after {
          right: 1.4em;
          bottom: 1.4em; } }
  .header__connection {
    width: 100%;
    height: 100%;
    padding: 20px 0;
    text-align: center;
    color: #FFFFFF;
    background: #03B781;
    transition: background ease-in-out 0.2s; }
    .header__connection:before {
      content: '';
      display: none;
      width: 12px;
      height: 12px;
      margin-right: 10px;
      background: #03B781;
      border-radius: 50%; }
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      .header__connection {
        padding: 4px 0;
        font-size: 0.9em; } }
    @media only screen and (min-width: 57.5625em) {
      .header__connection {
        text-align: left;
        color: rgba(255, 255, 255, 0.5);
        background: transparent; }
        .header__connection:before {
          display: inline-block; } }

.header-connection-status {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100%;
  z-index: 10; }
  @media only screen and (min-width: 57.5625em) {
    .header-connection-status {
      top: 80px;
      left: 80px;
      width: calc(95% - 80px - 1em);
      max-width: 64em;
      min-height: 0;
      max-height: calc(100% - 80px - 4em);
      padding: 2em 0;
      background: #343a43;
      overflow: auto;
      transition: background 0.1s ease-out, max-height 0.3s ease-out; }
      .header-connection-status--hidden {
        max-height: 0;
        background: rgba(56, 63, 73, 0);
        transition: background 0.3s ease-in 0.1s, max-height 0.3s ease-in 0.1s; } }
  .header-connection-status:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    backdrop-filter: blur(10px);
    background: rgba(42, 47, 54, 0.98);
    transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
    @media only screen and (min-width: 57.5625em) {
      .header-connection-status:before {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        backdrop-filter: none;
        z-index: -2; } }
  .header-connection-status--hidden:before {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition: opacity 0.3s ease-out, transform 0s ease-out 0.3s; }
  .header-connection-status__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2em;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    opacity: 1;
    transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
    @media only screen and (min-width: 57.5625em) {
      .header-connection-status__content {
        position: static;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        padding: 2em; } }
  .header-connection-status--hidden .header-connection-status__content {
    opacity: 0;
    transition: opacity 0.1s ease-out, transform 0s ease-out 0.3s; }
  .header-connection-status__item {
    display: block;
    padding: 1em;
    font-family: Monaco, monospace;
    letter-spacing: -0.05em;
    word-break: normal;
    color: #c2c8d0; }
  .header-connection-status__time {
    display: block;
    float: left;
    width: 7em;
    color: #57616f; }
  .header-connection-status__message {
    display: block;
    padding-left: 8em; }

/*.header-documents {
	position: absolute;
	top: calc(env(safe-area-inset-top) + 60px);
	width: 100%;
	min-height: 750px;

	background: rgba($background-footer, 0.95);
}*/
.header-documents {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100%;
  z-index: 10; }
  @media only screen and (min-width: 57.5625em) {
    .header-documents {
      top: 80px;
      left: 0;
      width: 32em;
      min-height: 0;
      max-height: calc(100% - 80px - 4em);
      background: #343a43;
      overflow: auto;
      transition: background 0.1s ease-out, max-height 0.3s ease-out; }
      .header-documents--hidden {
        max-height: 0;
        background: rgba(56, 63, 73, 0);
        transition: background 0.3s ease-in 0.1s, max-height 0.3s ease-in 0.1s; } }
  .header-documents:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    backdrop-filter: blur(10px);
    background: rgba(42, 47, 54, 0.9);
    transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
    @media only screen and (min-width: 57.5625em) {
      .header-documents:before {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: none;
        background: transparent;
        z-index: -2; } }
  .header-documents--hidden:before {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition: opacity 0.3s ease-out, transform 0s ease-out 0.3s; }
  .header-documents__list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    padding: 6.5em 5%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (min-width: 57.5625em) {
      .header-documents__list {
        position: static;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        padding: 2em 0; } }
  .header-documents__item {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
    .header-documents__item--hidden {
      opacity: 0;
      transform: translate3d(40px, 0, 0);
      transition: opacity 0.1s ease-out, transform 0s ease-out 0.3s; }
      @media only screen and (min-width: 57.5625em) {
        .header-documents__item--hidden {
          display: block;
          transform: translate3d(0, 0, 0); } }
    .header-documents__item + .header-documents__item {
      margin-top: 1em; }
  .header-documents__link {
    display: block;
    width: 100%;
    height: 3.7em;
    position: relative;
    padding: 0.3em 1.8em 0.5em 5.4em;
    text-decoration: none;
    text-align: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    @media only screen and (min-width: 57.5625em) {
      .header-documents__link {
        height: 5.8em;
        padding: 1.3em 3.6em 1.5em 8.6em; }
        .header-documents__link:not(.header-documents__link--add):hover {
          background: #2d323a; } }
    .header-documents__link:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 2em;
      height: 2em;
      font-size: 1.8em;
      line-height: 2em;
      color: #a6aeb9;
      background: #353b44;
      border-radius: 50%; }
      @media only screen and (min-width: 57.5625em) {
        .header-documents__link:before {
          top: 0.6em;
          left: 1.8em;
          cursor: pointer;
          background: #3c434d; } }
    .header-documents__link--add {
      padding: 0.9em 3.6em 1.0em 8.6em;
      cursor: default; }
      .header-documents__link--add:before {
        content: '+';
        line-height: 1.8em;
        color: #8994a3;
        background: #2e343c; }
  .header-documents__name {
    display: block;
    color: #8994a3;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .header-documents__tasks {
    display: block;
    color: #6d7a8c;
    font-size: 1em; }

.header-settings {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100%;
  z-index: 10; }
  @media only screen and (min-width: 57.5625em) {
    .header-settings {
      top: 80px;
      right: 0;
      width: calc(95% - 80px - 1em);
      max-width: 64em;
      min-height: 0;
      max-height: calc(100% - 80px - 4em);
      padding: 2em 0;
      background: #343a43;
      overflow: auto;
      transition: background 0.1s ease-out, max-height 0.3s ease-out; }
      .header-settings--hidden {
        max-height: 0;
        background: rgba(56, 63, 73, 0);
        transition: background 0.3s ease-in 0.1s, max-height 0.3s ease-in 0.1s; }
      .header-settings--noadmin {
        max-width: 44em; } }
  .header-settings:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    backdrop-filter: blur(10px);
    background: rgba(42, 47, 54, 0.9);
    transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
    @media only screen and (min-width: 57.5625em) {
      .header-settings:before {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        backdrop-filter: none;
        z-index: -2; } }
  .header-settings--hidden:before {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition: opacity 0.3s ease-out, transform 0s ease-out 0.3s; }
  .header-settings__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 6.5em 5%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (min-width: 57.5625em) {
      .header-settings__content {
        position: static;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        padding: 2em; } }
  .header-settings__mobile-update {
    margin: 2em auto 4em;
    padding: 1em;
    background: #E64C65;
    color: #FFFFFF;
    font-size: 0.8em;
    width: 320px;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
    @media only screen and (min-width: 57.5625em) {
      .header-settings__mobile-update {
        display: none; } }
    .header-settings__mobile-update--hidden {
      opacity: 0;
      transform: translate3d(40px, 0, 0);
      transition: opacity 0.1s ease-out, transform 0s ease-out 0.3s; }
      @media only screen and (min-width: 57.5625em) {
        .header-settings__mobile-update--hidden {
          transform: translate3d(0, 40px, 0); } }
  .header-settings__wrap {
    z-index: 1;
    width: 320px;
    margin: 0 auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.3s ease-out, transform 0.15s ease-out; }
    .header-settings__wrap + .header-settings__wrap {
      margin-top: 2em; }
    .header-settings__wrap--disabled {
      display: none; }
      @media only screen and (min-width: 57.5625em) {
        .header-settings__wrap--disabled {
          display: block;
          opacity: 0.4;
          pointer-events: none; } }
    .header-settings__wrap--hidden {
      opacity: 0;
      transform: translate3d(40px, 0, 0);
      transition: opacity 0.1s ease-out, transform 0s ease-out 0.3s; }
      @media only screen and (min-width: 57.5625em) {
        .header-settings__wrap--hidden {
          transform: translate3d(0, 40px, 0); } }
    .header-settings__wrap--version {
      padding: 1em 0;
      font-size: 0.8em;
      color: #666666;
      text-align: center; }
    @media only screen and (min-width: 57.5625em) {
      .header-settings__wrap {
        width: 32%;
        float: left; }
        .header-settings__wrap--mobile-avatar {
          display: none; }
        .header-settings__wrap + .header-settings__wrap {
          margin-top: 0;
          margin-left: 2%; }
        .header-settings__wrap--mobile-avatar + .header-settings__wrap {
          margin-top: 0;
          margin-left: 0; } }
  @media only screen and (min-width: 57.5625em) {
    .header-settings--noadmin .header-settings__wrap {
      width: 49%; } }
  .header-settings--noadmin .header-settings__wrap--admin {
    display: none; }
  .header-settings__mobile-avatar {
    position: relative;
    width: 6em;
    height: 6em;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden; }
  .header-settings__headline {
    color: #8994a3;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    @media only screen and (min-width: 57.5625em) {
      .header-settings__headline {
        padding: 0 0.83em; } }
  .header-settings__subheadline {
    margin: -0.2em 0 0.8em;
    color: #6d7a8c;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    @media only screen and (min-width: 57.5625em) {
      .header-settings__subheadline {
        padding: 0 1em; } }
  .header-settings__menu {
    margin: 0.4em 0 0.8em;
    background: rgba(235, 240, 246, 0.5);
    border-radius: 0.4em; }
  .header-settings__item + .header-settings__item {
    border-top: 1px solid rgba(42, 47, 54, 0.1); }
  .header-settings__link {
    display: block;
    position: relative;
    padding: 0.8em 1em;
    text-decoration: none;
    color: #22262C;
    /*&:before {
			content: '>';
			display: inline-block;
			position: absolute;
			top: 0.2em;
			right: 0.6em;
			bottom: 0;
			padding: 0.8em 0.6em;
			vertical-align: center;
			font-weight: bold;
			color: rgba($background-footer, 0.6);
		}*/ }
    @media only screen and (min-width: 57.5625em) {
      .header-settings__link:hover {
        background: rgba(235, 240, 246, 0.1); } }
    .header-settings__link--logout {
      color: #c91c38; }
  .header-settings__badge {
    display: inline-block;
    position: relative;
    top: -0.1em;
    left: 0.4em;
    width: 1.4em;
    height: 1.4em;
    background: #e15c34;
    border-radius: 50%;
    color: #FFFFFF;
    line-height: 1.4em;
    font-size: 0.8em;
    text-align: center; }
  .header-settings__version-line {
    display: block; }
    .header-settings__version-line + .header-settings__version-line {
      margin-top: 1em;
      color: #E64C65; }

.keychain-unlock {
  color: rgba(255, 255, 255, 0.8);
  background: rgba(42, 47, 54, 0.96); }
  @supports (backdrop-filter: grayscale(100%) blur(10px)) {
    .keychain-unlock {
      background: rgba(42, 47, 54, 0.8); } }
  .keychain-unlock.b-modal--hidden {
    background: rgba(42, 47, 54, 0); }
  .keychain-unlock__content {
    padding: 4em 0 30%; }
  .keychain-unlock__icon {
    display: block;
    margin: 0 auto;
    font-size: 2.8em;
    text-align: center;
    width: 2em; }
  .keychain-unlock__message {
    margin: 2em 0;
    font-size: 0.9em;
    opacity: 0.8; }
  .keychain-unlock__label {
    color: rgba(255, 255, 255, 0.6); }
  .keychain-unlock__input {
    border-color: #141619;
    background: #21252b;
    color: #EBF0F6;
    font-size: 1.4em; }
    .keychain-unlock__input--error {
      animation: error 0.85s cubic-bezier(0.35, 0.07, 0.2, 0.97) both; }
  .keychain-unlock__cancel {
    color: rgba(235, 240, 246, 0.9); }

@keyframes error {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(3px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-5px, 0, 0); }
  40%, 60% {
    transform: translate3d(5px, 0, 0); } }

.login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #2A2F36; }
  .login__wrap {
    position: absolute;
    top: 2em;
    left: 2em;
    right: 2em;
    bottom: 4em;
    background: #EBF0F6;
    border-radius: 6px;
    overflow: hidden; }
    @media only screen and (max-width: 40em) {
      .login__wrap {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0; }
        .login__wrap:before {
          content: '';
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: env(safe-area-inset-top);
          background: #2A2F36; } }
  .login__icons {
    font-family: 'ubud';
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 2.5em;
    font-variant: normal;
    text-transform: none;
    color: #c8d6e6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    .login__icons span {
      position: absolute;
      top: 50%;
      left: 50%; }
      @media only screen and (max-width: 40em) {
        .login__icons span {
          display: none; } }
      .login__icons span:nth-child(1) {
        margin: -180px 0 0 -200px;
        transform: rotate(20deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(2) {
        margin: -160px 0 0 -100px;
        transform: rotate(-3deg);
        font-size: 1.1em; }
      .login__icons span:nth-child(3) {
        margin: -195px 0 0 10px;
        transform: rotate(8deg);
        font-size: 1em; }
      .login__icons span:nth-child(4) {
        margin: -150px 0 0 100px;
        transform: rotate(-10deg);
        font-size: 1.1em; }
      .login__icons span:nth-child(5) {
        margin: -100px 0 0 190px;
        transform: rotate(8deg);
        font-size: 0.7em; }
      .login__icons span:nth-child(6) {
        margin: -120px 0 0 270px;
        transform: rotate(2deg);
        font-size: 1em; }
      .login__icons span:nth-child(7) {
        margin: -90px 0 0 350px;
        transform: rotate(-7deg);
        font-size: 0.7em; }
      .login__icons span:nth-child(8) {
        margin: -30px 0 0 390px;
        transform: rotate(2deg);
        font-size: 0.6em; }
      .login__icons span:nth-child(9) {
        margin: -25px 0 0 290px;
        transform: rotate(-4deg);
        font-size: 0.9em; }
      .login__icons span:nth-child(10) {
        margin: -35px 0 0 200px;
        transform: rotate(8deg);
        font-size: 0.9em; }
      .login__icons span:nth-child(11) {
        margin: 35px 0 0 240px;
        transform: rotate(2deg);
        font-size: 1.1em; }
      .login__icons span:nth-child(12) {
        margin: -170px 0 0 190px;
        transform: rotate(-6deg);
        font-size: 0.6em; }
      .login__icons span:nth-child(13) {
        margin: -118px 0 0 -20px;
        transform: rotate(-2deg);
        font-size: 0.6em; }
      .login__icons span:nth-child(14) {
        margin: 25px 0 0 340px;
        transform: rotate(2deg);
        font-size: 0.9em; }
      .login__icons span:nth-child(15) {
        margin: 20px 0 0 460px;
        transform: rotate(12deg);
        font-size: 0.6em; }
      .login__icons span:nth-child(16) {
        margin: 65px 0 0 410px;
        transform: rotate(6deg);
        font-size: 1.1em; }
      .login__icons span:nth-child(17) {
        margin: 90px 0 0 320px;
        transform: rotate(2deg);
        font-size: 0.9em; }
      .login__icons span:nth-child(18) {
        margin: 120px 0 0 260px;
        transform: rotate(-4deg);
        font-size: 1em; }
      .login__icons span:nth-child(19) {
        margin: 45px 0 0 180px;
        transform: rotate(2deg);
        font-size: 0.6em; }
      .login__icons span:nth-child(20) {
        margin: 125px 0 0 170px;
        transform: rotate(-6deg);
        font-size: 0.9em; }
      .login__icons span:nth-child(21) {
        margin: 95px 0 0 85px;
        transform: rotate(2deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(22) {
        margin: 125px 0 0 -10px;
        transform: rotate(5deg);
        font-size: 1em; }
      .login__icons span:nth-child(23) {
        margin: 125px 0 0 -70px;
        transform: rotate(-5deg);
        font-size: 0.7em; }
      .login__icons span:nth-child(24) {
        margin: 90px 0 0 -150px;
        transform: rotate(-2deg);
        font-size: 1em; }
      .login__icons span:nth-child(25) {
        margin: 150px 0 0 70px;
        transform: rotate(-8deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(26) {
        margin: 120px 0 0 -230px;
        transform: rotate(7deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(27) {
        margin: 50px 0 0 -210px;
        transform: rotate(-4deg);
        font-size: 0.9em; }
      .login__icons span:nth-child(28) {
        margin: -20px 0 0 -235px;
        font-size: 0.8em; }
      .login__icons span:nth-child(29) {
        margin: -70px 0 0 -170px;
        transform: rotate(5deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(30) {
        margin: -120px 0 0 -220px;
        transform: rotate(-2deg);
        font-size: 1em; }
      .login__icons span:nth-child(31) {
        margin: 85px 0 0 -370px;
        transform: rotate(-7deg);
        font-size: 1em; }
      .login__icons span:nth-child(32) {
        margin: 45px 0 0 -300px;
        transform: rotate(4deg);
        font-size: 1em; }
      .login__icons span:nth-child(33) {
        margin: 15px 0 0 -390px;
        transform: rotate(8deg);
        font-size: 1.1em; }
      .login__icons span:nth-child(33) {
        margin: -75px 0 0 -320px;
        transform: rotate(-4deg);
        font-size: 1.1em; }
      .login__icons span:nth-child(34) {
        margin: -160px 0 0 -310px;
        transform: rotate(3deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(35) {
        margin: -130px 0 0 -390px;
        transform: rotate(-6deg);
        font-size: 1.1em; }
      .login__icons span:nth-child(36) {
        margin: -10px 0 0 -360px;
        transform: rotate(-6deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(37) {
        margin: -60px 0 0 -430px;
        transform: rotate(9deg);
        font-size: 0.7em; }
      .login__icons span:nth-child(38) {
        margin: 30px 0 0 -430px;
        transform: rotate(9deg);
        font-size: 0.9em; }
      .login__icons span:nth-child(39) {
        margin: -20px 0 0 -500px;
        transform: rotate(-4deg);
        font-size: 0.8em; }
      .login__icons span:nth-child(40) {
        margin: 135px 0 0 -300px;
        transform: rotate(-4deg);
        font-size: 0.6em; }
      .login__icons span:nth-child(41) {
        margin: 105px 0 0 -435px;
        transform: rotate(7deg);
        font-size: 0.7em; }
      .login__icons span:nth-child(42) {
        margin: 60px 0 0 -480px;
        transform: rotate(7deg);
        font-size: 0.7em; }
      .login__icons span:nth-child(43) {
        margin: 155px 0 0 -120px;
        transform: rotate(7deg);
        font-size: 0.6em; }
  .login-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, rgba(235, 240, 246, 0) 0%, #EBF0F6 90%, #EBF0F6 100%); }
  .login__logo {
    position: absolute;
    top: 1em;
    left: 1em;
    width: 5em;
    height: 5em;
    background: transparent url("/icon.svg");
    background-size: cover;
    border-radius: 4px; }
    @media only screen and (max-width: 40em) {
      .login__logo {
        top: 50%;
        left: 50%;
        margin: -14em 0 0 -2em; } }
  .login__form {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 200px;
    margin: -100px 0 0 -150px; }
    .login__form-box {
      background: #EBF0F6;
      border: 4px solid #c8d6e6;
      border-radius: 6px;
      padding: 0 5px;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px; }
      .login__form-box--error {
        animation: error 0.85s cubic-bezier(0.35, 0.07, 0.2, 0.97) both; }
    .login__form-input {
      display: block;
      box-sizing: border-box;
      width: 100%;
      padding: 12px 10px;
      background: none;
      border: none;
      outline: none; }
      .login__form-input::placeholder {
        opacity: .6; }
      .login__form-input--error {
        background: rgba(230, 76, 101, 0.1); }
      .login__form-input:first-child {
        border-bottom: 1px solid #c8d6e6; }
  .login__terms {
    margin: 1em;
    font-size: 0.8em;
    overflow: hidden;
    color: rgba(51, 51, 51, 0.8); }
    @media only screen and (min-width: 57.5625em) {
      .login__terms {
        margin: 3em 2em 3em; } }
    .login__terms-message {
      float: right;
      width: calc(100% - 1.6em); }
  .login__submit {
    margin-top: 15px; }
  .login__minilinks {
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
    color: #666666; }
    .login__minilinks a:link,
    .login__minilinks a:hover {
      color: inherit; }
  .login__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: env(safe-area-inset-bottom);
    padding: 2em 2.5em;
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    text-align: center; }
    @media only screen and (max-width: 40em) {
      .login__footer {
        color: #999999; } }
    .login__footer a:link,
    .login__footer a:hover {
      color: inherit;
      text-decoration: none; }

.placeholder {
  width: 100%;
  height: 100%; }
  .placeholder__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3em 0 0 -5em;
    width: 10em;
    height: 6em;
    text-align: center; }
  .placeholder__icon {
    display: block;
    font-size: 2.8em;
    color: #b3b2b2; }
  .placeholder__name {
    display: block;
    margin-top: 0.4em;
    color: #999999; }

.reconcile__account {
  font-size: 1.4em;
  padding: 0;
  background: none; }
  .reconcile__account-select {
    text-align: center; }

.reconcile__prefix {
  font-size: 0.9em; }

.reconcile__amount {
  font-size: 1.4em;
  padding: 0.1em;
  margin: 0.4em 0 0.6em; }
  .reconcile__amount-input {
    text-align: center; }

.reconcile__adjustment {
  opacity: 0;
  margin: 2em 0 0;
  padding: 1em 0 1em 1em;
  border-left: 2px solid #03B781;
  transition: opacity 0.3s ease-out; }
  .reconcile__adjustment--positive, .reconcile__adjustment--negative {
    opacity: 1; }
  .reconcile__adjustment--negative {
    border-color: #E64C65; }
  .reconcile__adjustment-text {
    font-size: 0.8em;
    margin: 0 0 1.4em; }

.reconcile__buttons {
  margin-top: 2em;
  margin-bottom: -2em; }

.sidebar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 19;
  background: #FFFFFF;
  border-top: 1px solid #EBF0F6;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 0.3s; }
  @media only screen and (min-width: 57.5625em) {
    .sidebar {
      top: 80px;
      right: auto;
      bottom: 0;
      width: 80px;
      overflow: hidden;
      background: #FFFFFF;
      border-top: none;
      border-right: 1px solid #EBF0F6;
      transition: width 0.8s ease-in-out 0.25s, transform ease-out 0.3s; } }
  @media only screen and (min-width: 57.5625em) and (hover: hover) {
    .sidebar:not(.sidebar--hidden):not(.sidebar--mini):hover {
      width: 280px;
      transition: width 0.25s ease-out 2.4s, transform ease-out 0.3s; } }
  .sidebar--hidden {
    transform: translate3d(0, 100px, 0);
    transition: transform ease-in 0.3s; }
    @media only screen and (min-width: 57.5625em) {
      .sidebar--hidden {
        transform: translate3d(-80px, 0, 0);
        transition: width 0.8s ease-in-out 0.25s, transform ease-in 0.3s 0.3s; } }
  .sidebar__item {
    float: left;
    width: 20%;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    .sidebar__item--active {
      cursor: default; }
    .sidebar__item--settings-desktop {
      display: none;
      position: absolute;
      bottom: 0; }
    @media only screen and (min-width: 57.5625em) {
      .sidebar__item {
        display: block;
        float: none;
        width: 100%;
        border-left: 4px solid transparent;
        border-top: 1px solid #EBF0F6;
        border-bottom: 1px solid #EBF0F6; }
        .sidebar__item + .sidebar__item {
          border-top: none; }
        .sidebar__item--active {
          border-left-color: #312480; }
        .sidebar__item--settings-mobile {
          display: none; }
        .sidebar__item--settings-desktop {
          display: block; }
          .sidebar__item--settings-desktop:last-child {
            border-top: 1px solid #EBF0F6; } }
    .sidebar__item-link {
      display: block;
      text-decoration: none;
      padding: 10px;
      color: rgba(51, 51, 51, 0.6);
      cursor: inherit; }
      @media only screen and (min-width: 57.5625em) {
        .sidebar__item-link {
          width: 240px;
          height: 80px;
          padding: 0;
          box-sizing: content-box; }
          .sidebar__item-link:hover {
            color: #404853; }
          .sidebar__item-link:active {
            color: #404853; } }
    .sidebar__item-icon {
      display: block;
      font-family: 'ubud';
      font-style: normal;
      font-weight: normal;
      speak: none;
      font-size: 1.6em;
      font-variant: normal;
      text-transform: none;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      .sidebar__item-icon--add {
        color: #FFFFFF;
        font-size: 1.8em;
        padding: 4px 3px 4px 5px; }
      @media only screen and (min-width: 57.5625em) {
        .sidebar__item-icon {
          float: left;
          width: 76px;
          font-size: 2.0em;
          line-height: 80px;
          padding-right: 4px; } }
    .sidebar__item--active .sidebar__item-icon:not(.sidebar__item-icon--add) {
      color: rgba(49, 36, 128, 0.8); }
    .sidebar__item-name {
      display: none;
      float: left;
      white-space: nowrap;
      font-size: 1em;
      width: 160px;
      line-height: 80px;
      opacity: 0.5; }
      @media only screen and (min-width: 57.5625em) {
        .sidebar__item-name {
          display: block; } }
    .sidebar__item--right {
      float: right; }
    .sidebar__item--add {
      position: absolute;
      left: 50%;
      bottom: calc(3px + env(safe-area-inset-bottom));
      width: 3.6em;
      height: 3.6em;
      margin-left: -1.6em;
      background: #312480;
      border-radius: 50%; }
      @media only screen and (min-width: 57.5625em) {
        .sidebar__item--add {
          display: none; } }

.terms-notification {
  position: fixed;
  width: 100%;
  background: #64BBEF;
  padding: 2em;
  z-index: 30; }
  .terms-notification__close {
    float: right; }
  .terms-notification__headline {
    font-size: 1.1em;
    margin-top: 1em; }
  .terms-notification__message {
    font-size: 0.9em;
    margin-bottom: 2em; }
  .terms-notification__accept {
    margin-bottom: 0.4em; }
  .terms-notification__button--reject {
    display: none; }
  @media only screen and (min-width: 57.5625em) {
    .terms-notification {
      width: calc(100% - 4em); } }
  @media only screen and (min-width: 78.8125em) {
    .terms-notification {
      padding: 1em; }
      .terms-notification__headline {
        margin-top: 0; }
      .terms-notification__message {
        margin-bottom: 0; }
      .terms-notification__close {
        display: none; }
      .terms-notification__buttons {
        position: absolute;
        right: 2em;
        top: 0.65em;
        width: 24em; }
      .terms-notification__button--reject, .terms-notification__button--accept {
        float: right;
        width: calc(50% - 1em); }
      .terms-notification__button--reject {
        display: block;
        opacity: 0.6;
        margin-right: 2em; } }

@keyframes location-pulse {
  0% {
    opacity: 0.3; }
  70% {
    opacity: 0.8; }
  100% {
    opacity: 0.3; } }

.transaction-add {
  user-select: none; }
  .transaction-add__back, .transaction-add__location {
    display: block;
    position: absolute;
    top: calc(env(safe-area-inset-top, 0) - 0.2em);
    width: 3em;
    height: 3em;
    font-size: 1.4em;
    z-index: 10; }
  .transaction-add__back {
    left: -0.2em; }
  .transaction-add__location {
    right: -0.2em;
    color: #333333;
    opacity: 0.3; }
    .transaction-add__location--enabled {
      animation: location-pulse 1s infinite; }
    .transaction-add__location--active {
      opacity: 0.8;
      animation: none; }
    .transaction-add__location--error {
      opacity: 0.8;
      animation: none;
      color: #E64C65; }
  .transaction-add__amount, .transaction-add__options, .transaction-add__keyboard, .transaction-add__buttons {
    position: absolute;
    width: 100%; }
  .transaction-add__amount {
    top: calc(env(safe-area-inset-top, 0));
    bottom: calc(23.5em + env(safe-area-inset-bottom));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .transaction-add__amount--transfer {
      bottom: calc(26.6em + env(safe-area-inset-bottom)); }
    .transaction-add__amount-prelabel, .transaction-add__amount-postlabel {
      opacity: 0.8em;
      font-size: 0.8em; }
    .transaction-add__amount-prelabel {
      margin-bottom: 1em; }
    .transaction-add__amount-postlabel {
      margin-top: 1em; }
    .transaction-add__amount-calculation {
      font-size: 1.2em;
      opacity: 0.6;
      margin-bottom: -0.4em; }
    .transaction-add__amount-value {
      font-size: 3.2em; }
      .transaction-add__amount-value--green {
        color: #03B781; }
      .transaction-add__amount-value--red {
        color: #E64C65; }
      .transaction-add__amount-value--empty {
        opacity: 0.6;
        color: inherit; }
  .transaction-add__options {
    bottom: calc(20.5em + env(safe-area-inset-bottom)); }
  .transaction-add__option {
    display: block;
    width: 100%;
    background: rgba(42, 47, 54, 0.95);
    color: #FFFFFF;
    text-align: inherit;
    padding: 0.8em 1em;
    box-shadow: inset 0px -10px 10px -10px rgba(0, 0, 0, 0.7); }
    .transaction-add__option + .transaction-add__option {
      margin-top: 1px; }
  .transaction-add__account-type {
    opacity: 0.6; }
    .transaction-add__account-type:after {
      content: ':'; }
  .transaction-add__account-balance {
    opacity: 0.6;
    float: right; }
  .transaction-add__keyboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;
    bottom: calc(5.5em + env(safe-area-inset-bottom));
    height: 14em; }
    .transaction-add__keyboard-button {
      flex: 0 0 25%;
      font-size: 1.2em; }
      .transaction-add__keyboard-button--calculator {
        opacity: 0.4; }
  .transaction-add__buttons {
    padding: 1em 1em calc(1em + env(safe-area-inset-bottom));
    height: calc(5.5em + env(safe-area-inset-bottom));
    bottom: 0;
    overflow: hidden; }
  .transaction-add__button--cancel {
    transition: opacity 0.3s ease-out;
    opacity: 0; }
  .transaction-add__buttons--nosave .transaction-add__button--cancel {
    transition: opacity 0.3s ease-out 0.3s;
    opacity: 1; }
  .transaction-add__button--save {
    position: absolute;
    left: 1em;
    width: calc(100% - 2em);
    bottom: calc(1em + env(safe-area-inset-bottom));
    z-index: 1000;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease-out; }
  .transaction-add__buttons--nosave .transaction-add__button--save {
    transform: translate3d(0, calc(10em + env(safe-area-inset-bottom)), 0); }

.transaction-details {
  z-index: 30; }
  .transaction-details__header {
    overflow: hidden;
    min-height: 1em;
    margin: 0; }
  .transaction-details__close {
    display: block;
    float: right;
    width: 1.4em;
    height: 1.4em;
    font-size: 1.8em;
    line-height: 1em;
    color: #666666; }
  .transaction-details__field {
    position: relative; }
    @media only screen and (min-width: 40.0625em) {
      .transaction-details__field {
        float: left;
        width: calc(50% - 0.5em); }
        .transaction-details__field:nth-child(5) {
          width: 100%; } }
    .transaction-details__field + .transaction-details__field {
      margin-top: 1.2em; }
      @media only screen and (min-width: 40.0625em) {
        .transaction-details__field + .transaction-details__field {
          margin-top: 0; } }
    @media only screen and (min-width: 40.0625em) {
      .transaction-details__field:nth-child(3), .transaction-details__field:nth-child(4), .transaction-details__field:nth-child(5) {
        margin-top: 1.2em; }
      .transaction-details__field:nth-child(2n) {
        margin-left: 1em; } }
    .transaction-details__field-label {
      display: block;
      font-size: 0.8em;
      margin-left: 0.8em;
      color: #737373; }
    .transaction-details__field-input, .transaction-details__field-select-wrap {
      width: 100%;
      background: rgba(51, 51, 51, 0.1);
      padding: 0.6em;
      height: 2.6em; }
    .transaction-details__field-select {
      width: 100%;
      background: transparent;
      padding: 0;
      height: 1.4em; }
    .transaction-details__field-input-wrap {
      width: 100%;
      background: rgba(51, 51, 51, 0.1);
      padding: 0.6em;
      height: 2.6em; }
    .transaction-details__field-input-wrap .transaction-details__field-input {
      min-width: calc(100% - 1em);
      background: transparent;
      padding: 0;
      height: 1.4em; }
      .transaction-details__field-input-wrap .transaction-details__field-input--date {
        min-width: calc(50% - 1em);
        width: calc(50% - 1em);
        float: left; }
      .transaction-details__field-input-wrap .transaction-details__field-input--time {
        min-width: calc(50% - 1em);
        width: calc(50% - 1em);
        float: right; }
  .transaction-details__clearfix {
    clear: both; }
  .transaction-details__autocomplete {
    z-index: 2;
    min-width: 40%;
    max-width: 60%;
    position: absolute;
    background: #FFFFFF;
    margin-left: -1px;
    border: 1px solid #EBF0F6;
    border-right-width: 2px; }
    .transaction-details__autocomplete-suggestion {
      padding: 0.6em;
      border-left: 1px solid transparent; }
      .transaction-details__autocomplete-suggestion--selected {
        background: #f9fbfc;
        border-left: 2px solid #4C61EA; }
  .transaction-details__units {
    display: flex; }
    .transaction-details__units-headline {
      display: block;
      margin-top: 2em;
      padding: 1em 0 0.4em;
      color: rgba(51, 51, 51, 0.6);
      font-size: 0.8em; }
  .transaction-details__unit {
    height: 5.5em;
    background: rgba(51, 51, 51, 0.1);
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .transaction-details__unit + .transaction-details__unit {
      margin-left: 0.1em; }
    .transaction-details__unit--memo {
      height: 7.5em; }
    .transaction-details__unit--invalid {
      background: rgba(224, 31, 63, 0.6); }
    .transaction-details__unit--add {
      flex: 0 0 4em; }
      .transaction-details__unit--add-animate {
        transition: flex 0.1s ease-out; }
      .transaction-details__unit--add:first-child {
        flex: 1 1 auto; }
      .transaction-details__unit--add:hover:not(:first-child) {
        flex: 0 0 5em; }
    .transaction-details__unit-budget {
      width: 100%;
      padding: 1em; }
    .transaction-details__unit-amount {
      background: none;
      outline: none;
      width: 100%;
      text-align: center;
      font-size: 1.4em; }
    .transaction-details__unit-budget-select {
      display: inline;
      width: 90%;
      margin-left: 5%;
      background: transparent;
      text-align: center;
      text-align-last: center;
      color: #666666; }
    .transaction-details__unit-memo {
      width: 100%;
      background: transparent;
      color: #666666;
      font-size: 0.8em;
      text-align: center;
      margin-top: 1.2em; }
    .transaction-details__unit-add {
      width: 100%;
      height: 100%;
      line-height: 100%;
      color: rgba(128, 127, 127, 0.4);
      font-size: 3em; }
  .transaction-details--hidden .transaction-details__unit--invalid {
    background: #FFFFFF; }
  .transaction-details__quick-units {
    display: flex;
    margin-top: 2px;
    height: auto;
    max-height: 2em;
    overflow: hidden;
    transition: max-height 0.2s ease-out; }
    .transaction-details__quick-units--hidden {
      max-height: 0; }
  .transaction-details__quick-unit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .transaction-details__quick-unit + .transaction-details__quick-unit {
      margin-left: 2px; }
    .transaction-details__quick-unit-button {
      display: block;
      width: 100%;
      padding: 0.4em;
      font-size: 0.9em;
      background: rgba(51, 51, 51, 0.07); }
    .transaction-details__quick-unit:nth-child(5) {
      display: none; }
    .transaction-details__quick-unit:nth-child(3), .transaction-details__quick-unit:nth-child(4) {
      display: none; }
      @media only screen and (min-width: 57.5625em) {
        .transaction-details__quick-unit:nth-child(3), .transaction-details__quick-unit:nth-child(4) {
          display: block; } }
    @media only screen and (min-width: 40.0625em) {
      .transaction-details__quick-unit:nth-child(3) {
        display: block; } }
  .transaction-details__original {
    margin-top: 2em; }
    .transaction-details__original-toggle {
      padding: 1em 0 0.4em;
      color: rgba(51, 51, 51, 0.6);
      font-size: 0.8em; }
    .transaction-details__original-content {
      position: relative;
      background: rgba(51, 51, 51, 0.1);
      padding: 1em; }
    .transaction-details__original-datetime {
      display: block;
      font-size: 0.8em;
      margin-bottom: 0.6em;
      color: #666666; }
    .transaction-details__original-payee:empty:after, .transaction-details__original-memo:empty:after {
      content: attr(data-fallback);
      opacity: 0.7; }
    .transaction-details__original-memo {
      display: block;
      font-size: 0.8em;
      color: #737373; }
    .transaction-details__original-amount {
      position: absolute;
      display: block;
      top: 50%;
      margin-top: -1em;
      right: 1em;
      line-height: 2em; }
      .transaction-details__original-amount--negative {
        color: #E64C65; }
    .transaction-details__original-reconcile {
      padding: 0.8em;
      font-size: 0.8em;
      color: #8c8c8c; }

.transactions {
  min-height: 100%;
  user-select: none;
  padding: 0 0 4em; }
  @media only screen and (min-width: 57.5625em) {
    .transactions__pages {
      padding: 4em 0; } }
  @media only screen and (min-width: 78.8125em) {
    .transactions__pages {
      width: 1260px;
      margin: 0 auto; } }
  .transactions__separator {
    font-size: 1.4em;
    color: #666666;
    padding: 5em 0 1em 1em; }
    @media only screen and (min-width: 78.8125em) {
      .transactions__separator {
        padding-left: 0; } }
    .transactions__separator:last-child {
      display: none; }
  .transactions__item + .transactions__item {
    border-top: 1px solid #EBF0F6; }
  .transactions__button {
    display: block;
    width: 100%;
    position: relative;
    padding: 1em 1em 1em 0;
    overflow: hidden;
    text-align: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    .transactions__button:before {
      display: block;
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      bottom: 3px;
      width: 3px;
      background: #8A4FE3;
      border-radius: 0 5px 5px 0; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__button {
        padding: 1em; }
        .transactions__button:hover {
          background: #f6f8fb; } }
    .transactions__button--approved:before {
      background: #ddd; }
    .transactions__button--cleared:before {
      background: #03B781; }
    .transactions__button--pending {
      opacity: 0.6; }
  .transactions__date, .transactions__account, .transactions__payee, .transactions__budget, .transactions__memo, .transactions__amount {
    display: block;
    box-sizing: border-box;
    width: 52%;
    min-height: 1em;
    float: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__date, .transactions__account, .transactions__payee, .transactions__budget, .transactions__memo, .transactions__amount {
        width: 20%; }
        .transactions__date + .transactions__date, .transactions__account + .transactions__date, .transactions__payee + .transactions__date, .transactions__budget + .transactions__date, .transactions__memo + .transactions__date, .transactions__amount + .transactions__date, .transactions__date + .transactions__account, .transactions__account + .transactions__account, .transactions__payee + .transactions__account, .transactions__budget + .transactions__account, .transactions__memo + .transactions__account, .transactions__amount + .transactions__account, .transactions__date + .transactions__payee, .transactions__account + .transactions__payee, .transactions__payee + .transactions__payee, .transactions__budget + .transactions__payee, .transactions__memo + .transactions__payee, .transactions__amount + .transactions__payee, .transactions__date + .transactions__budget, .transactions__account + .transactions__budget, .transactions__payee + .transactions__budget, .transactions__budget + .transactions__budget, .transactions__memo + .transactions__budget, .transactions__amount + .transactions__budget, .transactions__date + .transactions__memo, .transactions__account + .transactions__memo, .transactions__payee + .transactions__memo, .transactions__budget + .transactions__memo, .transactions__memo + .transactions__memo, .transactions__amount + .transactions__memo, .transactions__date + .transactions__amount, .transactions__account + .transactions__amount, .transactions__payee + .transactions__amount, .transactions__budget + .transactions__amount, .transactions__memo + .transactions__amount, .transactions__amount + .transactions__amount {
          padding-left: 1.5em; } }
    @media only screen and (min-width: 78.8125em) {
      .transactions__date + .transactions__date, .transactions__account + .transactions__date, .transactions__payee + .transactions__date, .transactions__budget + .transactions__date, .transactions__memo + .transactions__date, .transactions__amount + .transactions__date, .transactions__date + .transactions__account, .transactions__account + .transactions__account, .transactions__payee + .transactions__account, .transactions__budget + .transactions__account, .transactions__memo + .transactions__account, .transactions__amount + .transactions__account, .transactions__date + .transactions__payee, .transactions__account + .transactions__payee, .transactions__payee + .transactions__payee, .transactions__budget + .transactions__payee, .transactions__memo + .transactions__payee, .transactions__amount + .transactions__payee, .transactions__date + .transactions__budget, .transactions__account + .transactions__budget, .transactions__payee + .transactions__budget, .transactions__budget + .transactions__budget, .transactions__memo + .transactions__budget, .transactions__amount + .transactions__budget, .transactions__date + .transactions__memo, .transactions__account + .transactions__memo, .transactions__payee + .transactions__memo, .transactions__budget + .transactions__memo, .transactions__memo + .transactions__memo, .transactions__amount + .transactions__memo, .transactions__date + .transactions__amount, .transactions__account + .transactions__amount, .transactions__payee + .transactions__amount, .transactions__budget + .transactions__amount, .transactions__memo + .transactions__amount, .transactions__amount + .transactions__amount {
        padding-left: 1.8em; } }
  .transactions__date {
    display: block;
    width: 20%;
    height: 100%;
    text-align: center;
    margin-top: 0.1em; }
    .transactions__date-big, .transactions__date-small, .transactions__date-full, .transactions__date-time {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .transactions__date-big {
      display: block;
      font-size: 1.6em;
      color: #807f7f; }
      .transactions__date-big:after {
        content: '.'; }
    .transactions__date-small {
      display: block;
      font-size: 0.8em;
      color: #4d4c4c;
      padding: 0 1.4em; }
    .transactions__date-full {
      display: none; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__date {
        width: 14%;
        text-align: left; }
        .transactions__date-big, .transactions__date-small {
          display: none; }
        .transactions__date-full {
          display: block; } }
  .transactions__button--memo .transactions__date {
    margin-top: 0.3em; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__button--memo .transactions__date {
        margin-top: 0; } }
  .transactions__button--memo .transactions__date-small {
    display: block; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__button--memo .transactions__date-small {
        display: none; } }
  .transactions__payee {
    font-size: 0.8em;
    color: #b3b2b2; }
    .transactions__payee--set {
      color: #4d4c4c; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__payee {
        font-size: 1em;
        color: #b3b2b2; }
        .transactions__payee--set {
          color: #333333; } }
  .transactions__button--memo .transactions__payee {
    margin-top: 0; }
  @media only screen and (min-width: 57.5625em) {
    .transactions__budget {
      width: 23%; } }
  @media only screen and (min-width: 57.5625em) {
    .transactions__budget--empty {
      opacity: 0; } }
  .transactions__memo {
    margin-top: 0.6em;
    font-size: 0.8em;
    color: #4d4c4c; }
    .transactions__memo:empty {
      display: none; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__memo {
        margin-top: 0;
        font-size: 1em;
        width: 30%;
        color: #666666; } }
  .transactions__amount {
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -0.6em;
    width: 25%;
    height: 1.2em;
    font-size: 1.2em;
    text-align: right;
    color: #4d4c4c;
    vertical-align: middle; }
    .transactions__amount--negative {
      color: #E64C65; }
    @media only screen and (min-width: 57.5625em) {
      .transactions__amount {
        font-size: 1em;
        width: 12%;
        height: auto; } }
  .transactions__dropzone {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 30;
    display: none;
    background: rgba(49, 36, 128, 0.95); }
    @supports (backdrop-filter: grayscale(100%) blur(10px)) {
      .transactions__dropzone {
        background: rgba(49, 36, 128, 0.85);
        backdrop-filter: grayscale(100%) blur(10px); } }
    .transactions__dropzone--visible {
      display: block; }
    .transactions__dropzone:before {
      content: '';
      position: absolute;
      top: 2em;
      left: 2em;
      right: 2em;
      bottom: 2em;
      border: 0.4em dashed rgba(255, 255, 255, 0.6);
      border-radius: 1em; }
    .transactions__dropzone-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -80px 0 0 -10em;
      width: 20em;
      text-align: center; }
    .transactions__dropzone-headline {
      color: #FFFFFF; }
    .transactions__dropzone-headline + .transactions__dropzone-message {
      color: rgba(255, 255, 255, 0.8); }

/*# sourceMappingURL=style.css.map */